










































































































































































































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import axios, { CancelTokenSource } from "axios";
import qs from "qs";
import _ from "lodash";

import { VueEditor, Quill } from "vue2-editor";
import BasePage from "./BasePage";
import ImportBatch from "../models/ImportBatch";
import EditBatchImportViewModel from "../models/EditBatchImportViewModel";
import Files from "../utils/Files";
import PagedList from "@/models/PagedList";
import ImportBatchStatus from "@/models/ImportBatchStatus";

// Testing local with ngork tunnel
// const baseURL = "https://2cf1-82-49-151-202.ngrok.io/";
const baseURL = process.env.VUE_APP_API_BASE_URL;

const CancelToken = axios.CancelToken;

@Component({
    components: {
        VueEditor
    }
})
export default class BatchImportEdit extends BasePage {
    headers: any[] = [
        {
            text: "Lang",
            value: "language",
            sortable: false,
            width: 40
        },
        {
            text: "Title",
            value: "title",
            sortable: false
        },
        {
            text: "Slug",
            value: "slug",
            sortable: false
        },
        {
            text: "Description",
            value: "description",
            sortable: false
        },
        {
            text: "Content",
            value: "content",
            sortable: false
        },
        {
            text: "DefaultText",
            value: "defaultText",
            sortable: false
        },
        {
            text: "MetaTitle",
            value: "metaTitle",
            sortable: false
        },
        {
            text: "MetaDescription",
            value: "metaDescription",
            sortable: false
        },
        {
            text: "Categories",
            value: "categories",
            sortable: false
        },
        {
            text: "Tags",
            value: "tags",
            sortable: false
        },
        {
            text: "Images",
            value: "images",
            sortable: false
        },
        {
            text: "Files",
            value: "files",
            sortable: false
        },
        {
            text: "Valid",
            value: "isRowValid",
            sortable: false,
            align: "center",
            width: 40
        },
        {
            text: "",
            value: "actions",
            sortable: false,
            align: "end",
            width: 80
        }
    ];
    
    search = {
        text: ""
    };
    
    selected: any[] = [];
    
    importRowInEdit: any = {};
    
    showRowEditDialog: Boolean = false;
    
    baseURL = baseURL;
    
    fileSelected = {
        name: "",
        content: ""
    };
    
    showFileContent: Boolean = false;
    
    showMetaContent: Boolean = false;
    
    showDataFromMetaModal: Boolean = false;
    
    extractedMeta: any = { files: [{}] };
    
    options = {};
    
    defaultPageSize: number = 50;
    
    footerProps = { 
        'items-per-page-options': [10, 25, 50, 100],
        options: {
            page: 1,
            itemsPerPage: 1/*,
            sortBy: ['createdDate'],
            sortDesc: [true]*/
        }
    };
    
    importBatchId: number = 0;
    
    model: EditBatchImportViewModel = new EditBatchImportViewModel(this.defaultPageSize);

    fileToUpload = null;
    
    acceptableFileTypes: string = "application/zip";
    
    fileUploadRules = [
        value => !value || value.size < 52428800 || "The file size should be less than " + Files.formatBytes(52428800),
    ];
    
    currentRequestToken: CancelTokenSource = null;
    
    editor_options = {
        modules: {
            toolbar: {
                container: [
                    [{ font: [] }],
            
                    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            
                    [{ size: ["small", false, "large", "huge"] }],
            
                    ["bold", "italic", "underline", "strike"],
            
                    [
                        { align: "" },
                        { align: "center" },
                        { align: "right" },
                        { align: "justify" },
                    ],
            
                    [{ header: 1 }, { header: 2 }],
            
                    ["blockquote", "code-block"],
            
                    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            
                    [{ script: "sub" }, { script: "super" }],
            
                    [{ indent: "-1" }, { indent: "+1" }],
            
                    [{ color: [] }, { background: [] }],
            
                    [{ direction: "rtl" }],
                    ["clean"]
                ]
            },
        },
    };
    
    get validSelected(): any[] {
        return this.selected.filter(f => f.isRowValid == true);
    };
    
    @Watch("$route")
    onRouteChange(to, from) {
        var instance = this;

        instance.readRouteParams(to);

        this.fetch();
    };
    
    _statusToString(status: ImportBatchStatus): string {
        return ImportBatchStatus[status];
    };
    
    truncate(text: string, length: number): string {
        if (!text) {
            return "";
        }
        
        return text.substring(0, Math.min(length, text.length)) + (text.length > length ? "..." : "");
    };
    
    readRouteParams(route) {
        var instance = this;
        
        instance.model.importRows.currentPage = route.query.page != null ? parseInt(route.query.page, 10) : 1;
        instance.model.importRows.pageSize = route.query.pageSize != null ? parseInt(route.query.pageSize, 10) : instance.defaultPageSize;
        instance.search.text = route.query.text;
        // instance.search.status = route.query.status;
    };
    
    onPageChange(page): void {
        let query = _.cloneDeep(this.$route.query);

        query.page = page;
        
        (this.$router as any).push({ name: "BatchImportEdit", query: query});    
    };
    
    onPageSizeChange(pageSize): void {
        let query = _.cloneDeep(this.$route.query);
        query.page = String(1);
        query.pageSize = pageSize;
        
        (this.$router as any).push({ name: "BatchImportEdit", query: query});
    };
    
    onFileUploadClick(): void {
        const instance = this;
        
        let _performUpload = () => {
            if (instance.fileToUpload) {
                let formdata = null;
                
                if ((window as any).FormData) {
                    formdata = new FormData();
                }
                
                formdata.append("file", instance.fileToUpload);
            
                instance.posting = true;
                
                axios.post(`/batchimports/${instance.importBatchId}/upload-zip`, formdata, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((result) => {
                        instance.fileToUpload = null;
                        instance.posting = false;
                        
                        instance.onFilterResetClick(null);
                        instance.applyFilters();
                }).catch((err) => {
                        if (err.response.data) {
                        instance.notifications.alert("Warning", err.response.data.errors.map(m => m.message).join("<br>"), { content: "import-warning-container" }, "800px");
                        }
                        instance.posting = false;
                });    
            }
        }
        
        if (instance.model.importRows.totalCount > 0) {
            instance.notifications.confirm("Warning", 
            "Current rows will be removed and replaced with the ones from the uploaded CSV. Do you want to proceed?",
            () => {
                _performUpload();
            },
            () => {
                
            });
        } else {
            _performUpload();
        }
    };
    
    onFilterResetClick(e): void {
        this.search.text = "";
    };
    
    fetch(): void {
        const instance = this;
        
        instance.posting = true;
        
        instance.currentRequestToken = CancelToken.source();
        
        axios.get(`/batchimports/${instance.importBatchId}`,{
            cancelToken: instance.currentRequestToken.token,
            params: {
                page: instance.model.importRows.currentPage,
                pageSize: instance.model.importRows.pageSize,
                text: instance.search.text,
                // status: instance.search.status,
                // sort: `${(instance.footerProps.options.sortDesc[0] == true ? "desc" : "asc")}(${instance.footerProps.options.sortBy[0]})`
            }
        })
            .then((result) => {
                instance.model = result.data;
                instance.footerProps.options.page = instance.model.importRows.currentPage as number;
                instance.footerProps.options.itemsPerPage = instance.model.importRows.pageSize as number;
                instance.currentRequestToken = CancelToken.source();
                instance.posting = false;
            }).catch((err) => {
                instance.posting = false;
            });
    };
    
    onEditRowClick(item: any): void {
        this.importRowInEdit = _.cloneDeep(item);
        
        this.showRowEditDialog = true;
    };
    
    onSaveEditedRowClick(): void {
        const instance = this;
        
        instance.posting = true;
        axios.post(`/batchimports/${instance.importBatchId}/row/${instance.importRowInEdit.id}`, instance.importRowInEdit)
            .then((result) => {
                instance.posting = false;
                instance.fetch();
                instance.showRowEditDialog = false;
            }).catch((err) => {
                if (err.response.data) {
                    instance.notifications.alert("Warning", err.response.data.errors.map(m => m.message).join("<br>"), { content: "import-warning-container" }, "800px");
                }
                instance.posting = false;
            });
    };
    
    onDeleteRowClick(item: any): void {
        const instance = this;
        
        instance.notifications.confirm("Warning", 
            "Do you want to delete the row?",
            () => {
                instance.posting = true;
                axios.delete(`/batchimports/${instance.importBatchId}/row/${item.id}`)
                    .then((result) => {
                        instance.posting = false;
                        instance.fetch();
                    }).catch((err) => {
                        if (err.response.data) {
                            instance.notifications.alert("Warning", err.response.data.errors.map(m => m.message).join("<br>"), { content: "import-warning-container" }, "800px");
                        }
                        instance.posting = false;
                    });
            },
            () => {
                
            });
    };
    
    onContentsFromDefaultTextsClick(e): void {
        this.performAction("contents-from-defaulttexts");
    };
    
    onContentsFromFilesClick(e) : void {
        this.notifications.confirm("Warning", 
            "This action will REPLACE the existing contents for the selected rows. Do you want to proceed?", 
            () => {
                this.performAction("contents-from-files-content");
            },
            () => {});
    };
    
    onDataFromMetaClick(e): void {
        this.showDataFromMetaModal = true;
    };
    
    onDataFromMetaActionClick(action): void {
        switch (action) {
            case 'title':
                this.performAction("titles-from-meta");
                break;
                
            case 'description':
                this.performAction("descriptions-from-meta");
                break;
                
            case 'tags':
                
                break;
        
            default:
                break;
        }
    };
        
    onReadFileContentsClick(e): void {
        this.performAction("extract-texts-from-files");
    };
    
    onReadFilesMetaClick(e): void {
        this.performAction("extract-meta-from-files");
    };
    
    onExtractImagesClick(e): void {
        this.performAction("extract-images-from-files");
    };
    
    performAction(action: string): void {
        const instance = this;
        
        instance.posting = true;
        
        axios.post(`/batchimports/${instance.importBatchId}/action`, null, {
            params: {
                importRowIds: instance.selected.map(m => m.id),
                action: action
            },
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: "repeat" })
            }
        })
            .then((result) => {
                instance.notifications.toast("Process succeeded");
                instance.fetch();
                instance.selected = [];
                instance.posting = false;
            }).catch((err) => {
                if (err.response.data) {
                    instance.notifications.alert("Warning", err.response.data.errors.map(m => m.message).join("<br>"), { content: "import-warning-container" }, "800px");
                }
                instance.posting = false;
            });
    };
    
    onValidateAllRowsClick() : void {
        const instance = this;
        
        instance.posting = true;
        
        axios.post(`/batchimports/${instance.importBatchId}/validate-all-rows`)
            .then((result) => {
                instance.notifications.toast("All the rows validated successfully");
                instance.fetch();
                instance.posting = false;
            }).catch((err) => {
                if (err.response.data) {
                    instance.notifications.alert("Warning", "<b>Some rows are not valid:</b><br>" + err.response.data.errors.map(m => m.message).join("<br>"), { content: "import-warning-container" }, "800px");
                }
                instance.fetch();
                instance.posting = false;
            });
    };
    
    applyFilters(): void {
        const instance = this;

        let query = {
            page: 1,
            text: instance.search.text
        };

        (this.$router as any).push({
            name: "BatchImportEdit",
            query: query,
            params: {
                id: instance.importBatchId
            }
        },
            () => { },
            () => {
                // onAbort:
                // If the route is not changing because the params are the same, it'll fire this callback
                instance.fetch();
            });
    };
    
    onSearch(e) {
        e.preventDefault();
        
        const instance = this;
        
        instance.applyFilters();
    };
    
    onFileClick(item, filename): void {
        const instance = this;
        
        instance.posting = true;
        
        axios.get(`/batchimports/${instance.importBatchId}/row/${item.id}/get-file-content`, {
            params: {
                fileName: filename
            }
        })
            .then((result) => {
                instance.fileSelected.name = filename;
                instance.fileSelected.content = result.data.content;
                
                instance.showFileContent = true;
                instance.posting = false;
            }).catch((err) => {
                instance.posting = false;
            });
    };
    
    onShowMetadataForRowClick(item): void {
        const instance = this;
        
        instance.posting = true;
        
        axios.get(`/batchimports/${instance.importBatchId}/row/${item.id}/metadata`)
            .then((result) => {
                instance.extractedMeta = result.data;
                
                instance.showMetaContent = true;
                instance.posting = false;
            }).catch((err) => {
                instance.posting = false;
            });
    };
    
    onImportRowsClick() {
        this.performAction("import");
    };
    
    buildGoogleDocumentsLink(file: string): string {
        const fileUrl = baseURL + `api/v1/contents/batch/${this.importBatchId}/file?name=${file}`;

        if (file.endsWith('.pdf')) {
            return fileUrl;    
        }

        return this.$store.getters.tcmsSettings.documentsPreview.googleDocsUrl + encodeURIComponent(fileUrl);
    };
    
    buildOfficeLink(file: string): string {
        const fileUrl = baseURL + `api/v1/contents/batch/${this.importBatchId}/file?name=${file}`;

        if (file.endsWith('.pdf')) {
            return fileUrl;    
        }
        
        return this.$store.getters.tcmsSettings.documentsPreview.officeUrl + encodeURIComponent(fileUrl);
    };
    
    buildPdfLink(file: string): string {
        const fileUrl = baseURL + `api/v1/contents/batch/${this.importBatchId}/file?name=${file}`;

        return fileUrl;
    };
    
    downloadFile(file: string): void {
        axios({
            url: baseURL + `api/v1/contents/batch/${this.importBatchId}/file?name=${file}`,
            method: 'GET',
            responseType: 'blob'
        })
            .then(res => {
                let blob = new Blob([res.data], { type: "application/octetstream" });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = file;
                link.click();
            })
            .catch(error => {
                console.error(error);
            });
    };
    
    mounted(): void {
        const instance = this;
        instance.importBatchId = Number(instance.$route.params.id);
        
        instance.readRouteParams(this.$route);
        
        instance.fetch();
    };
}
