import noscroll from "no-scroll";

export default class fullscreen {
    constructor(quill, options = {}) {
        this.quill = quill;
        this.options = options;
        this.fullscreen = false;
        
        this.editorcontainer = this.quill.container.parentNode;
    }
    
    handle() {
        if (!this.fullscreen) {
            this.fullscreen = true;
            this.editorcontainer.classList.add("editor-fullscreen");
            noscroll.on();
        } else {
            this.fullscreen = false;
            this.editorcontainer.classList.remove("editor-fullscreen");
            noscroll.off();
        }
    }
}
