import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        status: "",
        token: localStorage.getItem("token") || "",
        user: {},
        tcmsSettings: {}
    },
    getters: {
        isLoggedIn: (state) => () => {
            return !!state.token;
        },
        authStatus: (state) => state.status,
        tcmsSettings: (state) => state.tcmsSettings,
        currentUser: (state) => state.user
    },
    mutations: {
        auth_request(state) {
            state.status = "loading";
        },
        auth_success(state, { token, user }) {
            state.status = "success";
            state.token = token;
            state.user = user;
        },
        auth_error(state) {
            state.status = "error";
        },
        logout(state) {
            state.status = "";
            state.token = "";
        },
        storeTCMSSettings(state, { settings }) {
            state.tcmsSettings = settings;
        }
    },
    actions: {
        login({ commit }, data) {
            return new Promise((resolve, reject) => {
                commit("auth_request");
                axios({
                    url: "account/login",
                    data: data,
                    method: "POST",
                })
                    .then((resp) => {
                        const token = resp.data.token;
                        const user = resp.data.user;
                        localStorage.setItem("token", token);
                        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
                        commit("auth_success", { token, user });
                        resolve(resp);
                    })
                    .catch((err) => {
                        commit("auth_error");
                        localStorage.removeItem("token");
                        reject(err);
                    });
            });
        },
        logout({ commit }) {
            commit("logout");
            return new Promise((resolve, reject) => { 
                resolve(null);
            });
        },
        getTCMSSettings({ commit }) {
            return new Promise((resolve, reject) => {
                axios({
                    url: "settings",
                    method: "GET",
                })
                    .then((resp) => {
                        commit("storeTCMSSettings", { settings: resp.data });
                        resolve(resp);
                    })
                    .catch((err) => {
                        // TODO
                        reject(err);
                    });
            });
        }
    },
    modules: {},
});

export default store;