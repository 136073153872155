import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import store from "../store/index";
import _ from "lodash";

import BatchImports from "../views/BatchImports.vue";
import BatchImportEdit from "../views/BatchImportEdit.vue";
import Categories from "../views/Categories.vue";
import ChangePassword from "../views/ChangePassword.vue";
import CreateUser from "../views/CreateUser.vue";
import Dashboard from "../views/Dashboard.vue";
import DefaultTextEdit from "../views/DefaultTextEdit.vue";
import DefaultTexts from "../views/DefaultTexts.vue";
import Errors from "../views/Errors.vue";
import Login from "../views/Login.vue";
import Logs from "../views/Logs.vue";
import NotFound from "../views/NotFound.vue";
import Reviews from "../views/Reviews.vue";
import Tags from "../views/Tags.vue";
import Tenants from "../views/Tenants.vue";
import TenantEdit from "../views/TenantEdit.vue";
import Templates from "../views/Templates.vue";
import TemplateEdit from "../views/TemplateEdit.vue";
import Todos from "../views/Todos.vue";
import UserEdit from "../views/UserEdit.vue";
import Users from "../views/Users.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: "/",
        name: "Dashboard",
        component: Dashboard,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/notfound",
        name: "NotFound",
        component: NotFound,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/login",
        name: "Login",
        component: Login,
    },
    {
        path: "/change-password",
        name: "ChangePassword",
        component: ChangePassword,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/batchimports",
        name: "BatchImports",
        component: BatchImports,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/batchimports/:id",
        name: "BatchImportEdit",
        component: BatchImportEdit,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/logs",
        name: "Logs",
        component: Logs,
        meta: {
            requiresAuth: true,
            requiresRoles: ["admin"]
        },
    },
    {
        path: "/errors",
        name: "Errors",
        component: Errors,
        meta: {
            requiresAuth: true,
            requiresRoles: ["admin"]
        },
    },
    {
        path: "/users",
        name: "Users",
        component: Users,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/create-user",
        name: "CreateUser",
        component: CreateUser,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/users/:id",
        name: "UserEdit",
        component: UserEdit,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/tenants",
        name: "Tenants",
        component: Tenants,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/tenants/:id",
        name: "TenantEdit",
        component: TenantEdit,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/templates",
        name: "Templates",
        component: Templates,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/templates/:id",
        name: "TemplateEdit",
        component: TemplateEdit,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/todos",
        name: "Todos",
        component: Todos,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/categories",
        name: "Categories",
        component: Categories,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/tags/:tenantId",
        name: "Tags",
        component: Tags,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/default-texts/:tenantId",
        name: "DefaultTexts",
        component: DefaultTexts,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/edit-default-text/:tenantId/:id",
        name: "DefaultTextEdit",
        component: DefaultTextEdit,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/reviews",
        name: "Reviews",
        component: Reviews,
        meta: {
            requiresAuth: true,
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn()) {
            const user = store.getters.currentUser;
            
            if (to.meta.requiresRoles && to.meta.requiresRoles.length > 0) {
                if (_.some(to.meta.requiresRoles, (p) => { return user.roles.find(f => f == p) != null }) == false) {
                    next("/notfound");
                }
            }
            
            next();
            return;
        }
        
        next("/login");
    } else {
        next();
    }
});

export default router;
