















































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import _ from "lodash";

import Constants from "@/utils/Constants";
import Notifications from "@/utils/Notifications";
import Files from "@/utils/Files";

import TemplateViewModel from "@/models/TemplateViewModel";
import TemplateMediaType from "@/models/TemplateMediaType";
import TemplateMediaViewModel from "@/models/TemplateMediaViewModel";

const baseURL = process.env.VUE_APP_API_BASE_URL;

@Component
export default class TemplateEdit_Images extends Vue {
    @Prop()
    template: TemplateViewModel;
    
    @Watch("posting")
    onPostingChange(to) {
        this.$emit("postingChange", to);
    };
  
    imageToUpload: any = null;
    
    imageUploadRules = [
        value => !value || value.size < this.$store.getters.tcmsSettings.fileSizeLimits.images || "The image size should be less than " + Files.formatBytes(this.$store.getters.tcmsSettings.fileSizeLimits.images),
    ];
    
    baseURL = baseURL;
    
    showBigImage: Boolean = false;
    
    bigImage: String = null;
    
    /**
     * Is true if an AJAX operation is in progress
     */
    posting: Boolean = false;
    
    /**
     * If true means the current template is new, never been saved to DB
     */
    get isNew(): Boolean {
        return this.template.id == Constants.newId;
    };
    
    get templateImages(): TemplateMediaViewModel[] {
        var images = this.template.media.filter(f => f.type == TemplateMediaType.Image);
        
        return _.orderBy(images, "displayOrder");
    };
    
    onImageUploadClick(): void {
        const instance = this;
        
        if (instance.imageToUpload) {
            let formdata = null;
            
            if ((window as any).FormData) {
                formdata = new FormData();
            }
            
            formdata.append("image", instance.imageToUpload);
        
            instance.posting = true;
            
            axios.post(`/templates/${instance.template.id}/upload-image`, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              })
              .then((result) => {
                    instance.imageToUpload = null;
                    instance.template.media = result.data;
                    instance.posting = false;
                    
                    instance.$emit("imagesChanged");
              }).catch((err) => {
                    if (err.response.data) {
                       Notifications.alert("Warning", err.response.data.map(m => m.description).join("<br>"));
                    }
                    instance.posting = false;
              });    
        }
    };
    
    /**
     * Removes an element from the Templates' Media
     * @param image The image to remove from the Templates' Media list
     */
    onMediaRemoveClick(image: TemplateMediaViewModel): void {
        this.template.media = this.template.media.filter(f => f.id != image.id);
        
        // Consolidate displayOrder on remaining images
        _.forEach(this.template.media, (e, i) => { 
            e.displayOrder = i + 1;
        });
    };
    
    /**
     * Change the display order of the given image, switching the position with the others
     * @param image 
     * @param i 
     */
    onChangeOrderClick(image: TemplateMediaViewModel, i: number): void {
        let currentInPosition = this.template.media.find(f => f.displayOrder == i);
        currentInPosition.displayOrder = image.displayOrder;
        image.displayOrder = i;
    };
    
    showImageZoomed(image: TemplateMediaViewModel): void {
        this.bigImage = this.getImageUrl(image.mediaPath);
        this.showBigImage = true;
    };
    
    getImageUrl(imagePath: String): string {
        return this.baseURL + 'templates/image/' + imagePath;
    };
};
