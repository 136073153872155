import Vue from 'vue';
import moment from 'moment';

export default function Register() {
    Vue.filter('formatDate', function (value) {
        if (value) {
            return moment(new Date(value)).format('YYYY-MM-DD HH:mm')
        }
        return "";
    });    
    
    Vue.filter('formatOnlyDate', function (value) {
        if (value) {
            return moment(new Date(value)).format('YYYY-MM-DD')
        }
        return "";
    });    
};
