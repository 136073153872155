var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"lg":"6"}},[_c('h2',[_vm._v("Create a new user")]),_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [(_vm.validationErrors.length > 0)?_c('v-alert',{attrs:{"type":"error"}},[_c('ul',_vm._l((_vm.validationErrors),function(error,i){return _c('li',{key:i},[_vm._v(_vm._s(error.message))])}),0)]):_vm._e(),_c('v-form',{ref:"form"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Username","type":"text","error-messages":errors,"required":""},model:{value:(_vm.user.userName),callback:function ($$v) {_vm.$set(_vm.user, "userName", $$v)},expression:"user.userName"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Email","type":"email","error-messages":errors,"required":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('v-text-field',{attrs:{"label":"Phone number","type":"text"},model:{value:(_vm.user.phoneNumber),callback:function ($$v) {_vm.$set(_vm.user, "phoneNumber", $$v)},expression:"user.phoneNumber"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"Password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","type":_vm.user.password
                                                    ? 'password'
                                                    : 'text',"error-messages":errors,"required":""},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}})]}}],null,true)})],1),_c('v-col',{attrs:{"lg":"6"}},[_c('validation-provider',{attrs:{"name":"Confirm password","rules":"required|min:6"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                                    var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Confirm password","error-messages":errors,"type":_vm.user.confirmPassword
                                                    ? 'password'
                                                    : 'text',"required":""},model:{value:(_vm.user.confirmPassword),callback:function ($$v) {_vm.$set(_vm.user, "confirmPassword", $$v)},expression:"user.confirmPassword"}})]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('v-select',{attrs:{"items":_vm.availableRoles,"menu-props":{ maxHeight: '400' },"label":"Roles","multiple":"","hint":"Select roles for this user","persistent-hint":""},model:{value:(_vm.user.roles),callback:function ($$v) {_vm.$set(_vm.user, "roles", $$v)},expression:"user.roles"}})],1)],1),_c('br'),_c('v-btn',{staticClass:"mr-4",attrs:{"type":"submit","disabled":invalid},on:{"click":_vm.onSubmitClick}},[_vm._v(" submit ")])],1)],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }