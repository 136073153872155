



























































































































































import Vue from "vue";
import { Component, Watch, Prop } from "vue-property-decorator";
import { VueEditor, Quill } from "vue2-editor";
import { required } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import axios from "axios";
import _ from "lodash";

import fullscreen from "@/quillmodules/fullscreen";
Quill.register("modules/fullscreen", fullscreen, true);

import Constants from "@/utils/Constants";
import Notifications from "@/utils/Notifications";

import TemplateViewModel from "@/models/TemplateViewModel";
import TemplateFAQViewModel from "@/models/TemplateFAQViewModel";

setInteractionMode("eager");

extend("required", {
    ...required,
    message: "{_field_} can not be empty",
});

@Component({
    components: { VueEditor, ValidationObserver, ValidationProvider }
})
export default class TemplateEdit_FAQs extends Vue {
    @Prop()
    template: TemplateViewModel;
    
    @Prop()
    savedTemplate: TemplateViewModel = null;
    
    showFAQDialog: Boolean = false;
    
    faqInEdit: TemplateFAQViewModel = new TemplateFAQViewModel();
    
    editor_options = {
        modules: {
            fullscreen: {},
            toolbar: {
                container: [
                    [{ 'header': [1, 2, 3, 4, 5, 6, ] }],
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    ['bold', 'italic'],
                    ['link'],
                    ['fullscreen']
                ],
                handlers: {
                    fullscreen: () => {
                        console.log((this.$refs as any).faqEditor.quill);
                        (this.$refs as any).faqEditor.quill.getModule("fullscreen").handle();
                    },
                },
            },
        },
    };
    
    posting: Boolean = false;
    
    invalid: Boolean = false;
    
    dialogValidationErrors: any[] = [];
    
    /**
     * If true means the current template is new, never been saved to DB
     */
    get isNew(): Boolean {
        return this.template.id == Constants.newId;
    };
    
    get sortedFAQs(): TemplateFAQViewModel[] {
        return _.orderBy(this.template.faQs, (o) => o.displayOrder);
    };
    
    get hasPendingChanges(): Boolean {
        if (this.savedTemplate == null)
            return false;
            
        return !_.isEqual(this.savedTemplate.faQs, this.template.faQs);
    };
    
    @Watch("posting")
    onPostingChange(to) {
        this.$emit("postingChange", to);
    };
    
    onAddNewFAQClick(): void {
        this.invalid = false;
        this.faqInEdit = new TemplateFAQViewModel();
        this.showFAQDialog = true;
    };
    
    onSaveFAQClick(): void {
        const instance = this;
        
        instance.posting = true;
        
        if (instance.faqInEdit.id == "") {
            axios.post(`/templates/${instance.template.id}/add-new-faq`, {
                question: instance.faqInEdit.question,
                answer: instance.faqInEdit.answer,
                isPublished: instance.faqInEdit.isPublished
            })
                .then((result) => {
                    instance.template.faQs = result.data;
                    instance.$emit("faqsChanged");
                    instance.posting = false;
                    instance.showFAQDialog = false;
                }).catch((err) => {
                    if (err.response.data) {
                        instance.dialogValidationErrors = err.response.data;
                    }
                    
                    instance.posting = false;
                });
        } else {
            axios.post(`/templates/${instance.template.id}/faq`, instance.faqInEdit)
                .then((result) => {
                    instance.template.faQs = result.data;
                    instance.$emit("faqsChanged");
                    instance.posting = false;
                    instance.showFAQDialog = false;
                }).catch((err) => {
                    if (err.response.data) {
                        instance.dialogValidationErrors = err.response.data;
                    }
                    
                    instance.posting = false;
                });
        }
    };
    
    onEditFAQClick(faq): void {
        this.faqInEdit = faq;
        this.showFAQDialog = true;
    };
    
    onRemoveFAQClick(): void {
        const instance = this;
        
        Notifications.confirm("Confirm FAQ removal",
                              "Are you sure you want to remove this FAQ?",
                              () => {
                                  instance.posting = true;
                                  axios.delete(`/templates/${instance.template.id}/faq/${instance.faqInEdit.id}`)
                                    .then((result) => {
                                        instance.template.faQs = result.data;
                                        instance.$emit("faqsChanged");
                                        instance.posting = false;
                                        instance.showFAQDialog = false;
                                    }).catch((err) => {
                                        if (err.response.data) {
                                            instance.dialogValidationErrors = err.response.data;
                                        }
                                        
                                        instance.posting = false;
                                    });
                              },
                              () => {
                                  
                              });
    };
    
    onChangeOrderClick(faq: TemplateFAQViewModel, i: number): void {
        console.log(i);
        
        let currentInPosition = this.template.faQs.find(f => f.displayOrder == i);
        currentInPosition.displayOrder = faq.displayOrder;
        faq.displayOrder = i;
    };
};

