import UserLite from "./UserLite";

export default class TemplateFAQViewModel {
    public id: string = "";
    public displayOrder: number = 0;
    public question: string = "";
    public answer: string = "";
    public createdDate: Date = new Date();
    public createdBy: UserLite = new UserLite();
    public lastUpdatedDate: Date = new Date();
    public lastUpdatedBy: UserLite = new UserLite();
    public publishedDate: Date = new Date();
    public publishedBy: UserLite = new UserLite()
    public isPublished: Boolean = false;
};