import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { required, email, min, max } from "vee-validate/dist/rules";
import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
} from "vee-validate";
import axios from "axios";
import _ from "lodash";
import { VueEditor, Quill } from "vue2-editor";
import urlSlug from "url-slug";

import fullscreen from "@/quillmodules/fullscreen";
import defaultTexts from "@/quillmodules/defaultTexts";
Quill.register("modules/fullscreen", fullscreen, true);
Quill.register("modules/defaultTexts", defaultTexts, true);

import BasePage from "./BasePage";
import EditTemplateViewModel from "@/models/EditTemplateViewModel";
import LanguageDTO from "@/models/LanguageDTO";
import TenantLite from "@/models/TenantLite";
import TemplateStatus from "@/models/TemplateStatus";
import TemplateMediaViewModel from "@/models/TemplateMediaViewModel";
import TemplateMediaType from "@/models/TemplateMediaType";
import TemplateViewModel from "@/models/TemplateViewModel";

import TemplateEdit_Categories from "@/components/TemplateEdit_Categories.vue";
import TemplateEdit_DefaultTexts from "@/components/TemplateEdit_DefaultTexts.vue";
import TemplateEdit_Files from "@/components/TemplateEdit_Files.vue";
import TemplateEdit_Images from "@/components/TemplateEdit_Images.vue";
import TemplateEdit_Tags from "@/components/TemplateEdit_Tags.vue";
import TemplateEdit_FAQs from "@/components/TemplateEdit_FAQs.vue";
import TemplateEdit_CustomProperties from "@/components/TemplateEdit_CustomProperties.vue";
import TemplateEdit_Todos from "@/components/TemplateEdit_Todos.vue";
import DefaultTextViewModel from "@/models/DefaultTextViewModel";

const baseURL = process.env.VUE_APP_API_BASE_URL;

setInteractionMode("eager");

extend("required", {
    ...required,
    message: "{_field_} can not be empty",
});

extend("max", {
    ...max,
    message: "{_field_} must be maximum {length} characters",
});

@Component({
    components: {
        VueEditor,
        ValidationObserver,
        ValidationProvider,
        "template-edit-categories": TemplateEdit_Categories,
        "template-edit-default-texts": TemplateEdit_DefaultTexts,
        "template-edit-files": TemplateEdit_Files,
        "template-edit-images": TemplateEdit_Images,
        "template-edit-tags": TemplateEdit_Tags,
        "template-edit-faqs": TemplateEdit_FAQs,
        "template-edit-customproperties": TemplateEdit_CustomProperties,
        "template-edit-todos": TemplateEdit_Todos
    },
})
export default class TemplateEdit extends BasePage {
    /**
     * Constant to represent a new Templated id
     */
    newId: String = "00000000-0000-0000-0000-000000000000";

    // Obsolete? To remove?
    templateId: String = "00000000-0000-0000-0000-000000000000";

    /**
     * The actual model of the page
     */
    model: EditTemplateViewModel = new EditTemplateViewModel();

    /**
     * This represents the model as returned by the fetchTemplate function.
     * Partial updates of the model (e.g. files and media updload) should also realign this with their fragment of JSON, so that we know
     * that when this model is different from model.template we have a pending change to be saved.
     */
    savedTemplate: TemplateViewModel = null;

    /**
     * Indicates if the current value in the model.template.metaTitle was actually entered by the user
     */
    metaTitleEntered: Boolean = false;

    /**
     * Indicates if the current value in the model.template.metaDescription was actually entered by the user
     */
    metaDescriptionEntered: Boolean = false;

    /**
     * Indicates if the current value in the model.template.urlSlug was actually entered by the user
     */
    urlSlugEntered: Boolean = false;

    /**
     * Original status of the template, as saved on DB.
     * This is used to revert the select in case the status update function returns a validation error.
     */
    previousStatus: TemplateStatus = TemplateStatus.New;

    /**
     * Status to be changed.
     * Detached by the general model to not mess up with the hasPendingChanges computed.
     */
    status: TemplateStatus = TemplateStatus.New;

    /**
     * All the languages supported by the system
     */
    availableLanguages: LanguageDTO[] = [];

    invalid: Boolean = false;

    /**
     * @returns { any[] }
     */
    validationErrors: any[] = [];

    $refs!: {
        observer: InstanceType<typeof ValidationObserver>;
    };

    statuses: any[] = [
        { value: TemplateStatus.New, label: "New" },
        { value: TemplateStatus.Concept, label: "Concept" },
        { value: TemplateStatus.UnderReview, label: "UnderReview" },
        { value: TemplateStatus.Published, label: "Published" },
        { value: TemplateStatus.Unknown, label: "Unknown" },
    ];

    urlSlugWarning: String = null;

    baseURL = baseURL;

    editorQuill = null;

    editor_options = {
        modules: {
            fullscreen: {},
            defaultTexts: {},
            toolbar: {
                container: [
                    [{ font: [] }],
            
                    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            
                    [{ size: ["small", false, "large", "huge"] }],
            
                    ["bold", "italic", "underline", "strike"],
            
                    [
                        { align: "" },
                        { align: "center" },
                        { align: "right" },
                        { align: "justify" },
                    ],
            
                    [{ header: 1 }, { header: 2 }],
            
                    ["blockquote", "code-block"],
            
                    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            
                    [{ script: "sub" }, { script: "super" }],
            
                    [{ indent: "-1" }, { indent: "+1" }],
            
                    [{ color: [] }, { background: [] }],
            
                    ["link", "image", "video", "formula"],
            
                    [{ direction: "rtl" }],
                    ["clean"],
                    ["fullscreen"],
                    ["defaultTexts"]
                ],
                handlers: {
                    fullscreen: () => {
                        (this.$refs as any).contentEditor.quill.getModule("fullscreen").handle();
                    },
                    defaultTexts: (a) => {
                        this.$emit("openDefaultTextsDialog");
                    }
                },
            },
        },
    };

    showDefaultTexts: Boolean = false;
    
    selectedDefaultTextToAdd: DefaultTextViewModel = null;
    
    get contentWords() {
        if (!this.model.template.content) return 0;

        var content = this.model.template.content;
        var cleaned = content
            .replace(/<\S[^><]*>/gi, " ")
            .replace(/(\r\n|\n|\r)/gm, " ");

        return cleaned.match(/\w+/g) ? cleaned.match(/\w+/g).length : 0;
    }

    /**
     * If true means the current template is new, never been saved to DB
     */
    get isNew(): Boolean {
        return this.model.template.id == this.newId;
    }

    /**
     * Represents the list of languages still available to create a new related Template
     */
    get missingLanguagesForRelated(): LanguageDTO[] {
        let languagesAssociated = this.model.template.relatedTemplatesByLanguage.map(
            (m) => m.languageId
        );

        if (this.model.template.language != null) {
            languagesAssociated.push(this.model.template.language.id);
        }

        return _.filter(
            this.availableLanguages,
            (i) => _.findIndex(languagesAssociated, (x) => x == i.id) == -1
        );
    }

    /**
     * Represents the list of tenants still available to create a new related Template
     */
    get missingTenantsForRelated(): TenantLite[] {
        let tenantsAssociated = this.model.template.relatedTemplatesByTenant.map(
            (m) => m.tenantId
        );

        if (this.model.template.tenant != null) {
            tenantsAssociated.push(this.model.template.tenant.id);
        }

        return _.filter(
            this.model.availableTenants,
            (i) => _.findIndex(tenantsAssociated, (x) => x == i.id) == -1
        );
    }

    get templateImages(): TemplateMediaViewModel[] {
        var images = this.model.template.media.filter(
            (f) => f.type == TemplateMediaType.Image
        );

        return _.orderBy(images, "displayOrder");
    }

    get metaTitleValidationRules(): any {
        if (this.previousStatus == TemplateStatus.Published) {
            return { required: true, max: { length: 60 } };
        } else {
            return {};
        }
    }

    get urlSlugValidationRules(): any {
        if (this.previousStatus == TemplateStatus.Published) {
            return { required: true };
        } else {
            return {};
        }
    }

    get descriptionValidationRules(): any {
        if (this.previousStatus == TemplateStatus.Published) {
            return { required: true };
        } else {
            return {};
        }
    }

    get metaDescriptionValidationRules(): any {
        if (this.previousStatus == TemplateStatus.Published) {
            return { required: true, max: { length: 160 } };
        } else {
            return {};
        }
    }

    get contentValidationRules(): any {
        if (this.previousStatus == TemplateStatus.Published) {
            return { required: true };
        } else {
            return {};
        }
    }

    /**
     * Returns true if the model has pending changes that need to be saved.
     */
    get hasPendingChanges(): Boolean {
        if (this.savedTemplate == null) return true;

        return !_.isEqual(this.savedTemplate, this.model.template);
    }

    /**
     * Returns true if the Update status button can be pressed
     */
    get canUpdateStatus(): Boolean {
        if (this.isNew) return false;

        if (this.status != this.previousStatus && !this.hasPendingChanges)
            return true;

        return false;
    }

    debouncedUrlCheck = _.debounce(() => this.checkUrlSlugUniqueness(), 400);

    @Watch("$route")
    onRouteChange(to, from): void {
        var instance = this;

        instance.templateId = instance.$route.params.id;
        instance.fetchTemplate();
    };

    @Watch("model.template.urlSlug")
    onUrlSlugChange(to, from): void {
        if (to && to !== from) {
            this.debouncedUrlCheck();
        }
    };

    onContentEditorReady(e) {
        // console.log(e);
        // console.log("this.model.template.language.id ", this.model.template.language.id);

        this.editorQuill = e;
    };
    
    handleContentImageAdded(file, Editor, cursorLocation, resetUploader) {
        var instance = this;
        
        var formData = new FormData();
        formData.append("image", file);
        
        axios.post(`/contents/upload-content-image`, formData)
            .then((result) => {
                let url = (new URL(result.data.url, baseURL)).href;
                Editor.insertEmbed(cursorLocation, "image", url);
                resetUploader();
            }).catch((err) => {
                if (err.response.data) {
                    instance.notifications.alert("Warning", err.response.data.map(m => m.description).join("<br>"));
                 }
            });
    };

    /**
     * Returns the error messages for the UrlSlug field, mixing together validation messages and external uniqueness check (working as a warning)
     */
    getUrlSlugErrorMessages(errors) {
        let _errors = _.clone(errors);

        if (this.urlSlugWarning != null) {
            _errors.push(this.urlSlugWarning);
        }

        return [..._errors];
    }

    /**
     * Changing tenant on the select input
     */
    onTenantChange(): void {
        const instance = this;

        // This should always be true, as the Tenant can be changed only for new Templates (not yet saved)
        if (instance.model.template.id == instance.newId) {
            // When the tenant changes, if there are already categories selected for this template we've to clean them, as we cannot grant the new
            // Tenant has the same set of categories.
            instance.model.template.categories = [];
        }
    }

    onTitleKeyUp(e): void {
        const instance = this;

        if (!instance.metaTitleEntered)
            instance.model.template.metaTitle = instance.model.template.title;

        if (!instance.urlSlugEntered)
            instance.model.template.urlSlug = urlSlug(
                instance.model.template.title
            );
    }

    onMetaTitleKeyUp(e): void {
        if (this.model.template.metaTitle) this.metaTitleEntered = true;
        else this.metaTitleEntered = false;
    }

    onDescriptionKeyUp(e): void {
        const instance = this;

        if (!instance.metaDescriptionEntered)
            instance.model.template.metaDescription =
                instance.model.template.description;
    }

    onMetaDescriptionKeyUp(e): void {
        if (this.model.template.metaDescription)
            this.metaDescriptionEntered = true;
        else this.metaDescriptionEntered = false;
    }

    onUrlSlugKeyUp(e): void {
        if (this.model.template.urlSlug) this.urlSlugEntered = true;
        else this.urlSlugEntered = false;
    }

    onAddRelatedByLanguageClick(event, language: LanguageDTO): void {
        event.preventDefault();

        const instance = this;

        instance.notifications.confirm(
            "Confirm",
            `Do you want to create a new template for the language ${language.description} starting from this one?`,
            () => {
                instance.posting = true;

                axios
                    .post(
                        `/templates/${instance.model.template.id}/copy-to-language/${language.id}`
                    )
                    .then((result) => {
                        axios
                            .get(
                                `/templates/${instance.model.template.id}/templates-language-relate`
                            )
                            .then((res) => {
                                instance.model.template.relatedTemplatesByLanguage =
                                    res.data;
                                instance.savedTemplate.relatedTemplatesByLanguage = _.cloneDeep(
                                    instance.model.template
                                        .relatedTemplatesByLanguage
                                );

                                instance.posting = false;
                                instance.notifications.toast(
                                    "New templated created!"
                                );
                            })
                            .catch((err) => {
                                instance.posting = false;
                            });
                    })
                    .catch((err) => {
                        // TODO: Manage errors

                        instance.posting = false;
                    });
            },
            () => {}
        );
    }

    onAddRelatedByTenantClick(event, tenant: TenantLite): void {
        event.preventDefault();

        const instance = this;

        instance.notifications.confirm(
            "Confirm",
            `Do you want to create a new template for the tenant ${tenant.name} starting from this one?`,
            () => {
                instance.posting = true;

                axios
                    .post(
                        `/templates/${instance.model.template.id}/copy-to-tenant/${tenant.id}`
                    )
                    .then((result) => {
                        axios
                            .get(
                                `/templates/${instance.model.template.id}/templates-tenant-relate`
                            )
                            .then((res) => {
                                instance.model.template.relatedTemplatesByTenant =
                                    res.data;
                                instance.savedTemplate.relatedTemplatesByTenant = _.cloneDeep(
                                    instance.model.template
                                        .relatedTemplatesByTenant
                                );

                                instance.posting = false;
                                instance.notifications.toast(
                                    "New templated created!"
                                );
                            })
                            .catch((err) => {
                                instance.posting = false;
                            });
                    })
                    .catch((err) => {
                        // TODO: Manage errors

                        instance.posting = false;
                    });
            },
            () => {}
        );
    }

    onUpdateStatusClick(e) {
        const instance = this;

        instance.validationErrors = [];
        instance.posting = true;
        axios
            .post(
                `/templates/${instance.model.template.id}/update-status?status=${instance.status}`
            )
            .then((result) => {
                instance.notifications.toast("Status updated");
                instance.posting = false;

                instance.fetchTemplate();
            })
            .catch((err) => {
                if (err.response.data && err.response.data.errors) {
                    instance.validationErrors = err.response.data.errors;
                } else {
                    instance.validationErrors.push({
                        message: "An error occurred",
                    });
                }

                instance.status = instance.previousStatus;
                instance.posting = false;
            });
    }

    onSaveClick(e): void {
        const instance = this;

        instance.validationErrors = [];
        instance.posting = true;
        instance.$refs.observer.validate();

        if (!instance.invalid) {
            axios
                .post("/templates", instance.model.template)
                .then((result) => {
                    if (result.status == 201) {
                        instance.$router.push("/templates/" + result.data.id);
                    } else {
                        instance.notifications.toast("Template saved");
                        instance.posting = false;

                        instance.model.template = result.data.updatedTemplate;
                        instance.status = result.data.updatedTemplate.status;
                        instance.savedTemplate = _.cloneDeep(
                            instance.model.template
                        );
                    }
                })
                .catch((err) => {
                    if (err.response.data && err.response.data.errors) {
                        instance.validationErrors = err.response.data.errors;
                    } else {
                        instance.validationErrors.push({
                            message: "An error occurred",
                        });
                    }

                    instance.posting = false;
                });
        }
    }

    onDeleteClick(e): void {
        const instance = this;

        instance.notifications.confirm(
            "Confirm",
            `Do you want to delete the template?`,
            () => {
                instance.posting = true;

                axios
                    .delete(`/templates/${instance.model.template.id}`)
                    .then((result) => {
                        instance.$router.push("/templates");
                        instance.posting = false;
                    })
                    .catch((err) => {
                        instance.posting = false;
                    });
            },
            () => {}
        );
    }

    fetchLanguages(): void {
        const instance = this;

        axios
            .get("/languages")
            .then((result) => {
                instance.availableLanguages = result.data;
            })
            .catch((err) => {});
    }

    fetchTemplate(): void {
        const instance = this;

        instance.posting = true;
        axios
            .get(`/templates/${instance.$route.params.id}`)
            .then((result) => {
                instance.model = result.data;
                instance.status = result.data.template.status;
                instance.savedTemplate = _.cloneDeep(instance.model.template);

                instance.metaTitleEntered = !!instance.model.template.metaTitle;
                instance.metaDescriptionEntered = !!instance.model.template
                    .metaDescription;
                instance.urlSlugEntered = !!instance.model.template.urlSlug;
                instance.previousStatus = instance.model.template.status;

                instance.posting = false;

                // Post load adjustments
                instance.checkUrlSlugUniqueness();
                instance.setContentEditorLanguage();

                if (instance.newId == instance.model.template.id) {
                    if (instance.model.availableTenants.length == 1) {
                        instance.model.template.tenant =
                            instance.model.availableTenants[0];
                    }
                }
            })
            .catch((err) => {
                instance.posting = false;
            });

        if (instance.model.template.metaTitle) instance.metaTitleEntered = true;
    }

    checkUrlSlugUniqueness(): void {
        const instance = this;

        if (
            instance.model.template &&
            instance.model.template.id &&
            instance.model.template.tenant
        ) {
            axios
                .get(
                    `/templates/${instance.model.template.id}/check-url-slug`,
                    {
                        params: {
                            tenantId: instance.model.template.tenant.id,
                            urlSlug: instance.model.template.urlSlug,
                        },
                    }
                )
                .then((result) => {
                    if (result.data.success == false) {
                        instance.urlSlugWarning =
                            "WARNING: The given slug is not unique in the current Tenant";
                    } else {
                        instance.urlSlugWarning = null;
                    }
                })
                .catch((err) => {
                    instance.urlSlugWarning = null;
                });
        }
    }

    async setContentEditorLanguage() {
        while (this.editorQuill == null) {}

        if (this.model.template.language != null) {
            this.editorQuill.root.setAttribute("spellcheck", "true");
            this.editorQuill.root.setAttribute(
                "lang",
                this.model.template.language.id
            );    
        }
        // console.log(this.editorQuill.root.getAttribute("lang"));
    }

    onFilesChanged(): void {
        this.savedTemplate.files = _.cloneDeep(this.model.template.files);
    }

    onImagesChanged(): void {
        this.savedTemplate.media = _.cloneDeep(this.model.template.media);
    }

    onTagsChanged(): void {
        this.savedTemplate.tags = _.cloneDeep(this.model.template.tags);
    }

    onFaqsChanged(): void {
        this.savedTemplate.faQs = _.cloneDeep(this.model.template.faQs);
    }
    
    onSelectDefaultText(dt: DefaultTextViewModel): void {
        this.selectedDefaultTextToAdd = dt;
    };
    
    onReplaceWithDefaultTextClick(): void {
        let text = this.selectedDefaultTextToAdd.locales.find(f => f.languageId == this.model.template.language.id).text;
        
        this.model.template.content = this.interpolateText(text);
        
        this.showDefaultTexts = false;
    };
    
    onInsertDefaultTextClick(): void {
        let text = this.selectedDefaultTextToAdd.locales.find(f => f.languageId == this.model.template.language.id).text;

        if (this.model.template.content != null) {
            this.model.template.content = this.model.template.content + this.interpolateText(text);    
        } else {
            this.model.template.content = this.interpolateText(text);
        }
        
        this.showDefaultTexts = false;
    };
    
    interpolateText(text: string): string {
        text = text.replace(/{{Title}}/g, this.model.template.title);
        
        var defaultCategory = this.model.template.categories.find(a => a.isDefault);
        if (defaultCategory != null) {
            text = text.replace(/{{MainCategory}}/g, defaultCategory.categoryName);
        }
        
        return text;
    };

    mounted() {
        var instance = this;
        
        instance.templateId = instance.$route.params.id;
        instance.fetchLanguages();
        instance.fetchTemplate();
        instance.$refs.observer.validate();
        
        instance.$on("openDefaultTextsDialog", () => {
            if (instance.model.template.tenant == null ||
                instance.model.template.language == null) {
                    instance.notifications.alert("Warning", "You must select a tenant and a language before trying to add default texts");
            } else {
                instance.showDefaultTexts = true;
            
                setTimeout(() => {
                    // console.log((instance.$refs as any));
                    (instance.$refs as any).refDefaultTexts.fetch();    
                }, 200);    
            }
        });
    }
}
