var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('layout',[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"lg":"12"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('h2',[_vm._v("Default texts")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":("/edit-default-text/" + _vm.tenantId + "/0")}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v("New default text ")],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.defaultTexts.items,"disable-pagination":false,"hide-default-footer":false,"server-items-length":_vm.defaultTexts.totalCount,"items-per-page":_vm.defaultTexts.pageSize,"page":_vm.defaultTexts.currentPage,"loading":_vm.posting},on:{"update:page":_vm.onPageChange,"update:items-per-page":_vm.onPageSizeChange},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-form',{ref:"search",on:{"submit":_vm.onSearch}},[_c('v-container',[_c('v-row',{staticClass:"text-lg-center"},[_c('v-col',{attrs:{"lg":"3"}},[_c('v-text-field',{attrs:{"label":"Text"},model:{value:(_vm.search.text),callback:function ($$v) {_vm.$set(_vm.search, "text", $$v)},expression:"search.text"}})],1),_c('v-col',{attrs:{"lg":"2"}},[_c('v-text-field',{attrs:{"label":"Code"},model:{value:(_vm.search.code),callback:function ($$v) {_vm.$set(_vm.search, "code", $$v)},expression:"search.code"}})],1),_c('v-col',{staticClass:"text-right",attrs:{"lg":"2"}},[_c('v-btn',{attrs:{"type":"submit","color":"green"}},[_vm._v("Filter")]),_vm._v("  "),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.onFilterResetClick}},[_vm._v("Reset")])],1)],1)],1)],1)]},proxy:true},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.createdDate))+" ")]}},{key:"item.createdByUsername",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.createdBy.userName)+" ")]}},{key:"item.lastUpdatedDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.lastUpdatedDate))+" ")]}},{key:"item.lastUpdatedByUsername",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.lastUpdatedBy.userName)+" ")]}},{key:"item.languages",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.locales.map(function (m) { return m.languageId; }))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mr-1",attrs:{"small":"","to":("/edit-default-text/" + _vm.tenantId + "/" + (item.id))}},[_vm._v("Edit")]),_c('v-btn',{attrs:{"small":"","outlined":"","color":"error"},on:{"click":function($event){return _vm.onDeleteClick(item)}}},[_vm._v("Delete")])]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }