

































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { required, email, min } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import axios from "axios";

import BasePage from "./BasePage";

setInteractionMode("eager");

extend("email", {
    ...email,
    message: "Email must be valid",
});

extend("min", {
    ...min,
    message: "{_field_} should be greater or equals than {length} characters",
});

extend("required", {
    ...required,
    message: "{_field_} can not be empty",
});

@Component({
    components: { ValidationObserver, ValidationProvider },
})
export default class CreateUser extends BasePage {
    user: any = {
        userName: "",
        email: "",
        phoneNumber: "",
        password: "",
        confirmPassword: "",
        roles: [],
    };

    availableRoles: String[] = [];
    
    invalid: Boolean = false;
    
    validationErrors: any[] = [];

    fetchRoles() {
        const instance = this;

        axios
            .get("/account/roles")
            .then((result) => {
                instance.availableRoles = result.data;
            })
            .catch((err) => {});
    };

    onSubmitClick(event) {
        const instance = this;
        
        event.preventDefault();
        (instance.$refs.observer as any).validate();
        
        if (!instance.invalid) {
            axios.post("/account/users", instance.user)
                .then((result) => {
                    instance.notifications.toast("User created successfully");
                    instance.validationErrors = [];
                    
                    instance.$router.push("/users");
                }).catch((err) => {
                    if (err.response.data && err.response.data.errors) {
                        instance.validationErrors = err.response.data.errors;
                    }
                });
        }
    };

    clearForm() {
        this.user.userName = "";
        this.user.email = "";
        this.user.phoneNumber = "";
        this.user.password = "";
        this.user.confirmPassword = "";
        this.user.roles = [];
    };

    mounted() {
        this.fetchRoles();
        
        
    }
}
