import UserLite from "./UserLite";

export default class UserDTO extends UserLite {
    constructor() {
        super();
    }
    
    public email: String = "";
    public phoneNumber: String = "";
    public password: String = "";
    public confirmPassword: String = "";
    public roles: any[] = [];
    public tenants: any[] = [];
}