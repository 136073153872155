



















































































































































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { required, min_value } from "vee-validate/dist/rules";
import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
} from "vee-validate";
import axios from "axios";
import _ from "lodash";

import BasePage from "./BasePage";
import TenantViewModel from "@/models/TenantViewModel";

setInteractionMode("eager");

extend("required", {
    ...required,
    message: "{_field_} can not be empty",
});

extend("min_value", {
    ...min_value,
    message: "{_field_} must be at least {min}",
});

@Component({
    components: { ValidationObserver, ValidationProvider },
})
export default class TenantEdit extends BasePage {
    tenantId: Number = 0;

    tenant: TenantViewModel = new TenantViewModel();
    users: any[] = [];

    availableUsers: any[] = [];
    selectedUsers: any[] = [];

    availableCustomProperties: any[] = [];
    customProperties: any[] = [];
    selectedCustomProperties: any[] = [];

    invalid: Boolean = false;
    
    showDialogAddUser: Boolean = false;
    showDialogAddCustomProperty: Boolean = false;
    
    validationErrors: any[] = [];

    @Watch('$route', { immediate: true, deep: true })
    onRouteChange(newVal: any) {
        this.fetch();
    };

    fetch() {
        const instance = this;

        let id = Number(instance.$route.params.id);

        axios.get(`/tenants/${id}`)
             .then((result) => {
                instance.tenant = result.data.tenant;
                instance.users = result.data.users;
                instance.availableCustomProperties = result.data.availableCustomProperties;
                instance.customProperties = result.data.customProperties;
              })
              .catch((err) => {});
    };
    
    fetchAvailableUsers() {
        const instance = this;
        
        // TODO: This should exclude admin users, or in general should consider only "operative" users
        instance.availableUsers = [];
        
        axios.get("/account/users")
            .then((result) => {
                result.data.forEach(element => {
                    if (instance.users.findIndex((i) => i.id == element.id) == -1) {
                        instance.availableUsers.push(element);
                    }
                });
            }).catch((err) => {
                
            });
    };
    
    onAddUserClick(event) {
        this.showDialogAddUser = true;
        this.fetchAvailableUsers();
    };
    
    onSelectedUsersConfirm(event) {
        const instance = this;
        
        instance.selectedUsers.forEach((s) => {
            let user = instance.availableUsers.find(f => f.id == s);
            
            if (user) {
                instance.users.push(user);
            }
        });
        
        instance.selectedUsers = [];
        instance.showDialogAddUser = false;
    };
    
    onRemoveUser(user: any) {
        const instance = this;
        
        instance.users = instance.users.filter(f => f != user);
    };
    
    onAddCustomPropertyClick(event) {
        this.showDialogAddCustomProperty = true;
    };
    
    onSelectedCustomPropertyConfirm(event) {
        const instance = this;
        
        instance.selectedCustomProperties.forEach((s) => {
            let cp = instance.availableCustomProperties.find(f => f.key == s);
            
            if (cp) {
                instance.customProperties.push(cp);
            }
        });
        
        instance.selectedCustomProperties = [];
        instance.showDialogAddCustomProperty = false;
    };
    
    onRemoveCustomProperty(cp: any) {
        const instance = this;
        
        instance.customProperties = instance.customProperties.filter(f => f != cp);
    };

    onSubmitClick(event) {
        const instance = this;
        
        event.preventDefault();
        instance.posting = true;
        (instance.$refs.observer as any).validate();
        
        if (!instance.invalid) {
            axios.post("/tenants/", { tenant: instance.tenant, users: instance.users, customProperties: instance.customProperties })
                .then((result) => {
                    console.log(result);
                    
                    instance.notifications.toast("Tenant edited successfully");
                    instance.validationErrors = [];
                    instance.posting = false;
                    
                    instance.$router.push("/tenants/" + result.data.id);
                }).catch((err) => {
                    if (err.response && err.response.data && err.response.data.errors) {
                        instance.validationErrors = err.response.data.errors;
                    }
                    instance.posting = false;
                });
        }
    }

    mounted() {
        const instance = this;

        instance.fetch();
    }
}
