







import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import axios from "axios";

import { VueEditor, Quill } from "vue2-editor";
import fullscreen from "@/quillmodules/fullscreen";
Quill.register("modules/fullscreen", fullscreen, true);

import Notifications from "@/utils/Notifications";

const baseURL = process.env.VUE_APP_API_BASE_URL;

@Component({
    components: {
        VueEditor
    },
})
export default class DefaultTextEdit_Editor extends Vue {
    @Prop()
    defaultText;
    
    @Prop({ default: 0 })
    i;
    
    @Prop()
    languageId;
    
    editor_options = {
        modules: {
            fullscreen: {},
            toolbar: {
                container: [
                    [{ font: [] }],
            
                    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
            
                    [{ size: ["small", false, "large", "huge"] }],
            
                    ["bold", "italic", "underline", "strike"],
            
                    [
                        { align: "" },
                        { align: "center" },
                        { align: "right" },
                        { align: "justify" },
                    ],
            
                    [{ header: 1 }, { header: 2 }],
            
                    ["blockquote", "code-block"],
            
                    [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
            
                    [{ script: "sub" }, { script: "super" }],
            
                    [{ indent: "-1" }, { indent: "+1" }],
            
                    [{ color: [] }, { background: [] }],
            
                    ["link", "image", "video", "formula"],
            
                    [{ direction: "rtl" }],
                    ["clean"],
                    ["fullscreen"],
                ],
                handlers: {
                    fullscreen: (e, a) => {
                        (this.$refs as any).contentEditor.quill.getModule("fullscreen").handle();
                    },
                },
            },
        },
    };
    
    handleContentImageAdded(file, Editor, cursorLocation, resetUploader) {
        var instance = this;
        
        var formData = new FormData();
        formData.append("image", file);
        
        axios.post(`/contents/upload-content-image`, formData)
            .then((result) => {
                let url = (new URL(result.data.url, baseURL)).href;
                Editor.insertEmbed(cursorLocation, "image", url);
                resetUploader();
            }).catch((err) => {
                if (err.response.data) {
                    Notifications.alert("Warning", err.response.data.map(m => m.description).join("<br>"));
                 }
            });
    };
}
