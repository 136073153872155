










































import Vue from "vue";
import { Component } from "vue-property-decorator";
import axios from "axios";

import BasePage from "./BasePage";

@Component
export default class Tenants extends BasePage {
    tenants: any[] = [];
    
    headers: any[] = [
        {
            text: "Id",
            value: "id",
        },
        {
            text: "Name",
            value: "name",
        },
        {
            text: "Website Url",
            value: "websiteUrl",
            sortable: false
        },
        {
            text: "Enabled",
            value: "isEnabled",
            sortable: false,
            align: "end"
        },
        {
            text: "",
            value: "actions",
            sortable: false,
            align: "end"
        }
    ];
    
    fetch() {
        const instance = this;
        
        axios.get("/tenants/all")
            .then((result) => {
                instance.tenants = result.data;
            }).catch((err) => {
                
            });
    };
    
    mounted() {
        this.fetch();
    };
};
