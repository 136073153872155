








































































































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import { required, email, min } from "vee-validate/dist/rules";
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from "vee-validate";
import axios from "axios";

import BasePage from "./BasePage";
import UserDTO from "../models/UserDTO";

setInteractionMode("eager");

extend("email", {
    ...email,
    message: "Email must be valid",
});

extend("min", {
    ...min,
    message: "{_field_} should be greater or equals than {length} characters",
});

extend("required", {
    ...required,
    message: "{_field_} can not be empty",
});

@Component({
    components: { ValidationObserver, ValidationProvider },
})
export default class UserEdit extends BasePage {
    userId: String = "";
    
    user: UserDTO = new UserDTO();
    
    availableRoles: String[] = [];
    
    invalid: Boolean = false;
    
    validationErrors: any[] = [];
    
    availableTenants: any[] = [];
    selectedTenants: any[] = [];
    
    showDialogAddTenant: Boolean = false;
    
    fetchRoles() {
        const instance = this;

        axios
            .get("/account/roles")
            .then((result) => {
                instance.availableRoles = result.data;
            })
            .catch((err) => {});
    };
    
    fetchUser(id: String) {
        const instance = this;
        
        axios.get(`/account/users/${id}`)
            .then((result) => {
                instance.user = result.data;
            }).catch((err) => {
                
            });
    };
    
    fetchAvailableTenants() {
        const instance = this;
        
        instance.availableTenants = [];
    
        axios.get("/tenants/all")
            .then((result) => {
                result.data.forEach(element => {
                    if (instance.user.tenants.findIndex((i) => i.id == element.id) == -1) {
                        instance.availableTenants.push(element);
                    }
                });
            }).catch((err) => {
                
            });
    };
    
    onAddTenantClick() {
        this.showDialogAddTenant = true;
        this.fetchAvailableTenants();
    };
    
    onSelectedTenantsConfirm() {
        const instance = this;
        
        instance.selectedTenants.forEach((s) => {
            let user = instance.availableTenants.find(f => f.id == s);
            
            if (user) {
                instance.user.tenants.push(user);
            }
        });
        
        instance.selectedTenants = [];
        instance.showDialogAddTenant = false;
    };
    
    onRemoveTenant(tenant) {
        const instance = this;
        
        instance.user.tenants = instance.user.tenants.filter(f => f != tenant);
    };
    
    onSubmitClick(event) {
        const instance = this;
        
        event.preventDefault();
        instance.posting = true;
        (instance.$refs.observer as any).validate();
        
        if (!instance.invalid) {
            axios.put(`/account/users/${instance.userId}`, instance.user)
                .then((result) => {
                    instance.notifications.toast("User edited successfully");
                    instance.validationErrors = [];
                    instance.posting = false;
                }).catch((err) => {
                    if (err.response.data && err.response.data.errors) {
                        instance.validationErrors = err.response.data.errors;
                    }
                    instance.posting = false;
                });
        }
    };
    
    mounted() {
        const instance = this;
        
        instance.userId = instance.$route.params.id;
        
        instance.fetchRoles();
        instance.fetchUser(instance.$route.params.id);
    };
};

