

















































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import _ from "lodash";

import Constants from "@/utils/Constants";
import Notifications from "@/utils/Notifications";
import Files from "@/utils/Files";

import TemplateViewModel from "@/models/TemplateViewModel";
import TemplateFileViewModel from "@/models/TemplateFileViewModel";
import TemplateFileVersionViewModel from "@/models/TemplateFileVersionViewModel";

// Testing local with ngork tunnel
// const baseURL = "https://2cf1-82-49-151-202.ngrok.io/";
const baseURL = process.env.VUE_APP_API_BASE_URL;

@Component
export default class TemplateEdit_Files extends Vue {
    acceptableFileTypes: string = "application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .rtf, .dotx, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.presentationml.presentation, application/zip";
    
    @Prop()
    template: TemplateViewModel;
    
    @Watch("posting")
    onPostingChange(to) {
        this.$emit("postingChange", to);
    };
    
    /**
     * The new file to be uploaded
     */
    fileToUpload = null;
    
    /**
     * The file for the new version
     */
    fileForNewVersion = null;
    
    /**
     * The TemplateFile currently shown in the edit dialog modal (the version is selected from the most recent)
     */
    templateFileInEdit: TemplateFileViewModel = new TemplateFileViewModel();
    
    /**
     * Open/Close the dialog to edit the TemplateFile
     */
    showFileDialog: Boolean = false;
    
    /**
     * Is true if an AJAX operation is in progress
     */
    posting: Boolean = false;
    
    dialogValidationErrors: any[] = [];
    
    fileUploadRules = [
        value => !value || value.size < this.$store.getters.tcmsSettings.fileSizeLimits.files || "The file size should be less than " + Files.formatBytes(this.$store.getters.tcmsSettings.fileSizeLimits.files),
        // value => true,
    ];
    
    /**
     * If true means the current template is new, never been saved to DB
     */
    get isNew(): Boolean {
        return this.template.id == Constants.newId;
    };
    
    get templateFileInEditLastVersion(): TemplateFileVersionViewModel {
        let v = _.maxBy(this.templateFileInEdit.versions, (v) => v.createdDate);
        
        return v;
    };
    
    showFileSize(size: number): string {
        return Files.formatBytes(size);
    };
    
    onAddNewFileClick(): void {
        this.showFileDialog = true;
    };
    
    onFileUploadClick(): void {
        const instance = this;
        
        instance.dialogValidationErrors = [];
        
        if (instance.fileToUpload) {
            let formdata = null;
            
            if ((window as any).FormData) {
                formdata = new FormData();
            }
            
            formdata.append("file", instance.fileToUpload);
        
            instance.posting = true;
            
            axios.post(`/templates/${instance.template.id}/add-new-file`, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              })
              .then((result) => {
                    instance.fileToUpload = null;
                    instance.template.files.push(result.data);
                    instance.posting = false;
                    
                    instance.$emit("filesChanged");
              }).catch((err) => {
                    if (err.response.data) {
                       Notifications.alert("Warning", err.response.data.map(m => m.description).join("<br>"));
                    }
                    instance.posting = false;
              });    
        }
    };
    
    onVersionUploadClick(): void {
        const instance = this;
        
        instance.dialogValidationErrors = [];
        
        if (instance.fileForNewVersion) {
            let formdata = null;
            
            if ((window as any).FormData) {
                formdata = new FormData();
            }
            
            formdata.append("file", instance.fileForNewVersion);
        
            instance.posting = true;
            
            axios.post(`/templates/${instance.template.id}/file/${instance.templateFileInEdit.id}/add-version`, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
              })
              .then((result) => {
                    instance.fileForNewVersion = null;
                    var index = _.findIndex(instance.template.files, (e) => e.id == result.data.id);
                    instance.template.files.splice(index, 1, result.data);
                    instance.templateFileInEdit = result.data;
                    instance.posting = false;
                    
                    instance.$emit("filesChanged");
              }).catch((err) => {
                    if (err.response.data) {
                       instance.dialogValidationErrors = err.response.data;
                    }
                    instance.posting = false;
              });    
        }
    };
    
    /**
     * Given a file mimetype returns the appropriate icon
     * @param mimeType 
     * @returns 
     */
    decodeMimeTypeToIcon(mimeType: string): string {
        switch (mimeType) {
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
            case "application/msword":
                return "mdi-file-word";
        
            case "application/pdf":
                return "mdi-file-pdf";
                
            case "application/vnd.ms-excel":
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                return "mdi-file-excel";
        
            case "application/vnd.ms-powerpoint":
            case "application/vnd.openxmlformats-officedocument.presentationml.presentation":
                return "mdi-file-powerpoint";
        
            case "application/zip":
                return "mdi-folder-zip";
                
            default:
                return "mdi-file-document";
        }
    };
    
    onEditFileClick(file: TemplateFileViewModel): void {
        this.templateFileInEdit = _.cloneDeep(file);
        
        this.showFileDialog = true;
    };
    
    getLatestFileVersion(file: TemplateFileViewModel): TemplateFileVersionViewModel {
        let v = _.maxBy(file.versions, (v) => v.createdDate)
        
        return v;
    };
    
    onSaveFileVersionClick(): void {
        const instance = this;
        
        instance.posting = true;
        
        axios.post(`/templates/${instance.template.id}/update-file`, instance.templateFileInEdit)
            .then((result) => {
                instance.template.files = result.data;
                instance.showFileDialog = false;
                instance.posting = false;
                
                instance.$emit("filesChanged");
            }).catch((err) => {
                if (err.response.data) {
                    instance.dialogValidationErrors = err.response.data;
                }
                instance.posting = false;
            });
    };
    
    onRemoveFileClick(): void {
        const instance = this;
        
        Notifications.confirm("Confirm file removal", 
                              "Are you sure you want to remove this file?", 
                              () => {
                                  
                                  // TODO: implement, waiting for the task
                                  
                              }, 
                              () => {});
    };
    
    downloadFile(file: TemplateFileViewModel, fileVersion: TemplateFileVersionViewModel): void {
        axios({
            url: `/templates/file/${file.id}/download/${fileVersion.id}`,
            method: 'GET',
            responseType: 'blob'
        })
            .then(res => {
                let blob = new Blob([res.data], { type: file.mimeType });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileVersion.originalFilename;
                link.click();
            })
            .catch(error => {
                console.error(error);
            });
    };
    
    buildGoogleDocumentsLink(file: TemplateFileViewModel): string {
        const latestVersion = this.getLatestFileVersion(file);
        const fileUrl = baseURL + `api/v1/contents/${this.template.id}/file/${latestVersion.id}`;

        if (latestVersion.originalFilename.endsWith('.pdf')) {
            return fileUrl;    
        }

        return this.$store.getters.tcmsSettings.documentsPreview.googleDocsUrl + encodeURIComponent(fileUrl);
    };
    
    buildOfficeLink(file: TemplateFileViewModel): string {
        const latestVersion = this.getLatestFileVersion(file);
        const fileUrl = baseURL + `api/v1/contents/${this.template.id}/file/${latestVersion.id}`;

        if (latestVersion.originalFilename.endsWith('.pdf')) {
            return fileUrl;    
        }
        
        return this.$store.getters.tcmsSettings.documentsPreview.officeUrl + encodeURIComponent(fileUrl);
    };
    
    buildPdfLink(file: TemplateFileViewModel): string {
        const latestVersion = this.getLatestFileVersion(file);
        const fileUrl = baseURL + `api/v1/contents/${this.template.id}/file/${latestVersion.id}`;

        return fileUrl;
    };
};

