<template>
    <div>
        <layout>
            <h1>404</h1>    
        </layout>
    </div>
</template>

<script>
import Vue from "vue";
import { Component } from "vue-property-decorator";
import BasePage from "./BasePage";

@Component
export default class NotFound extends BasePage {
    
};

</script>
