import TenantConfigurationViewModel from "./TenantConfigurationViewModel";

export default class TenantViewModel {
    public apiKey: string = "";
    public configuration: TenantConfigurationViewModel = new TenantConfigurationViewModel();
    public description: string = "";
    public id: number = 0;
    public isEnabled: Boolean = false;
    public name: string = "";
    public webSiteUrl: string = "";
};