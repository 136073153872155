import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2';
import VJstree from "vue-jstree";
import { VueContext } from 'vue-context';

import "./assets/style.less";
import 'sweetalert2/dist/sweetalert2.min.css';
import '@mdi/font/css/materialdesignicons.css';

import _ from 'lodash';    
Object.defineProperty(Vue.prototype, '$_', { value: _ });

import Layout from "./views/Layout.vue";
import Filters from "./filters/Filters";

Filters();

Vue.config.productionTip = false

Vue.component("Layout", Layout);
Vue.component("VJstree", VJstree);
Vue.component("VueContext", VueContext);

Vue.use(VueSweetalert2);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
