



































































































































import Vue from "vue";
import { Component } from "vue-property-decorator";
import axios from "axios";
import { VueContext } from "vue-context";
import { required } from "vee-validate/dist/rules";
import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");

extend("required", {
    ...required,
    message: "{_field_} can not be empty",
});

// https://github.com/zdy1988/vue-jstree

import BasePage from "./BasePage";
import LanguageDTO from "@/models/LanguageDTO";
import EditCategoryViewModel from "@/models/EditCategoryViewModel";
import CategoryLocaleViewModel from "@/models/CategoryLocaleViewModel";

@Component({
    components: { VueContext, ValidationObserver, ValidationProvider },
})
export default class Categories extends BasePage {
    categories = [];

    categoryInEdit: any = null;

    showDialogAddCategory: Boolean = false;

    availableLanguages: LanguageDTO[] = [];

    newCategory: EditCategoryViewModel | null = null;

    invalid = false;
    
    tenantId: Number | null = null;
    
    pageTitle: String = "Base categories";
    
    $refs!: {
        observer: InstanceType<typeof ValidationObserver>;
    };

    onImportBaseCategoriesClick(e) {
        const instance = this;
        
        instance.posting = true;
        
        axios.post(`/categories/import-base/${instance.tenantId}`)
            .then((result) => {
                instance.fetchCategories();
                instance.posting = false;
            }).catch((err) => {
                instance.posting = false;
            });
    };

    itemEvents = {
        contextmenu: function(e, a, event) {
            event.preventDefault();
            e.$root.$emit("openCategoryContextMenu", event, { id: e.data.id });
        },
    };

    onCategorySelect(node, item, e) {
        const instance = this;

        axios
            .get(`/categories/${item.id}`)
            .then((result) => {
                instance.categoryInEdit = result.data;
            })
            .catch((err) => {});
    }

    onAddRootCategoryClick(e) {
        this.onAddCategoryClick(e, { id: null });
    };

    onAddCategoryClick(e, data) {
        e.preventDefault();

        const instance = this;

        instance.newCategory = new EditCategoryViewModel();
        instance.newCategory.parentId = data.id;
        instance.newCategory.locales = [];

        instance.availableLanguages.forEach((l) => {
            let cl = new CategoryLocaleViewModel();
            cl.languageId = l.id;
            cl.name = "";

            instance.newCategory.locales.push(cl);
        });

        instance.showDialogAddCategory = true;
    }

    onDeleteCategoryClick(e, data) {
        e.preventDefault();

        const instance = this;

        instance.notifications.confirm(
            "Confirm",
            "You want to delete the seleted category?",
            () => {
                instance.posting = true;

                axios
                    .delete(`/categories/${data.id}`)
                    .then((result) => {
                        instance.notifications.toast("Category deleted");
                        instance.posting = false;
                        instance.fetchCategories();
                    })
                    .catch((err) => {
                        instance.posting = false;
                    });
            },
            () => {
                
            }
        );
    }

    onAddCategoryConfirmClick() {
        const instance = this;

        // No way to have this work (I suspect is a bug related to dynamically generated components in v-for), so I'll redo a basic validation here
        // instance.$refs.observer.validate();
        
        let enName = instance.newCategory.locales.find(f => f.languageId == "en");
        
        if (enName.name) {
            
            axios.post(`/categories/create?tenantId=${instance.tenantId}`, instance.newCategory)
                .then((result) => {
                    instance.fetchCategories();
                    instance.showDialogAddCategory = false;
                    instance.notifications.toast("Category added");
                }).catch((err) => {
                    
                });
        }
    }

    onSaveCategoryEditClick(e) {
        var instance = this;

        instance.posting = true;
        
        axios.post(`/categories`, instance.categoryInEdit)
            .then((result) => {
                instance.notifications.toast("Category edited successfully");
                instance.posting = false;
                instance.fetchCategories();
                
                axios
                    .get(`/categories/${instance.categoryInEdit.id}`)
                    .then((result) => {
                        instance.categoryInEdit = result.data;
                    })
                    .catch((err) => {});
            })
            .catch((err) => {
                instance.posting = false;
            });
    }

    fetchCategories(): void {
        const instance = this;

        instance.posting = true;

        axios
            .get(`/categories/exact?tenantId=${instance.tenantId}`)
            .then((result) => {
                instance.categories = result.data;
                instance.posting = false;
            })
            .catch((err) => {
                instance.posting = false;
            });
    }

    fetchLanguages(): void {
        const instance = this;

        axios
            .get("/languages")
            .then((result) => {
                instance.availableLanguages = result.data;
            })
            .catch((err) => {});
    }

    mounted(): void {
        const instance = this;
        
        if (instance.$route.query.tenantId) {
            instance.tenantId = Number(instance.$route.query.tenantId);
            
            axios
                .get(`/tenants/${instance.tenantId}`)
                .then((result) => {
                    instance.pageTitle = `Categories of '${result.data.tenant.name}'`;
                });
        }
        
        instance.fetchLanguages();
        instance.fetchCategories();

        instance.$root.$on("openCategoryContextMenu", (event, data) => {
            (instance.$refs["menu"] as any).open(event, data);
        });
    }
}
