




































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import DefaultTextViewModel from "@/models/DefaultTextViewModel";

@Component
export default class TemplateEdit_DefaultTexts extends Vue {
    @Prop({ default: 0 })
    tenantId;
    
    @Prop({ default: "" })
    languageId;
    
    filter: string = "";
    
    posting: Boolean = false;
    
    defaultTexts: DefaultTextViewModel[] = [];
    
    selected: DefaultTextViewModel = null;
    
    get getSelectedText(): string {
        if (this.selected != null) {
            return this.selected.locales.find(f => f.languageId == this.languageId).text;
        }
        
        return null;
    };
    
    @Watch("selected")
    onSelectedChange(to): void {
        this.$emit("selectDefaultText", to);
    };
    
    onFilterEnter(e): void {
        e.preventDefault();
        e.stopPropagation();
        
        this.fetch();
    };
    
    onFilterClearClick(): void {
        var instance = this;
        setTimeout(() => {
            instance.fetch();
        }, 150);
    };
    
    onListItemClick(dt: DefaultTextViewModel): void {
        this.selected = dt;
    };
    
    fetch(): void {
        var instance = this;
        
        instance.selected = null;
        
        axios.get("/default-texts/search-for-association", {
            params: {
                tenantId: instance.tenantId, 
                languageId: instance.languageId, 
                filter: instance.filter
            }
        })
            .then((result) => {
                instance.defaultTexts = result.data.items;
            }).catch((err) => {
                
            });
    };
};
