


















































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import axios from "axios";

import DefaultTextEdit_Editor from "@/components/DefaultTextEdit_Editor.vue";
import BasePage from "./BasePage";
import DefaultTextViewModel from "@/models/DefaultTextViewModel";
import LanguageDTO from "@/models/LanguageDTO";
import DefaultTextLocaleViewModel from "@/models/DefaultTextLocaleViewModel";

@Component({
    components: {
        "default-text-edit-editor": DefaultTextEdit_Editor
    },
})
export default class DefaultTextEdit extends BasePage {
    id: number = 0;
    
    tenantId: number = 0;
    
    defaultText: DefaultTextViewModel = new DefaultTextViewModel();
    
    availableLanguages: LanguageDTO[] = [];
    
    availableVariables: string[] = [];
    
    selectedLanguage: number = 0;
    
    validationErrors: any[] = [];
    
    @Watch("$route")
    onRouteChange(to, from): void {
        var instance = this;

        instance.tenantId = Number(instance.$route.params.tenantId);
        instance.id = Number(instance.$route.params.id);
        instance.fetch();
    };
    
    fetchLanguages(): Promise<any> {
        const instance = this;
        
        return new Promise((resolve, reject) => {
            axios.get("/languages")
                .then((result) => {
                    instance.availableLanguages = result.data;
                    resolve(null);
                }).catch((err) => {
                    reject(null);
                });    
        });
    };
    
    fetch(): void {
        var instance = this;
        
        instance.posting = true;
        
        axios.get(`/default-texts/${instance.id}`)
            .then((result) => {
                instance.availableVariables = result.data.availableVariables;
                
                let res = result.data.defaultText;
                
                instance.availableLanguages.forEach((l) => {
                    if (res.locales.find(f => f.languageId == l.id) == null) {
                        var lang = new DefaultTextLocaleViewModel();
                        lang.languageId = l.id;
                        lang.text = "";
                        res.locales.push(lang);
                    }
                });
                instance.defaultText = res;
                instance.posting = false;
            }).catch((err) => {
                instance.posting = false;
            });
    };
    
    onSaveClick(): void {
        var instance = this;
        
        instance.posting = true;
        
        instance.validationErrors = [];
        instance.defaultText.tenantId = instance.tenantId;
        
        axios.post(`/default-texts/${instance.id}`, instance.defaultText)
            .then((result) => {
                if (result.status == 201) {
                    instance.$router.push(`/edit-default-text/${instance.tenantId}/${result.data.id}`);
                } else {
                    instance.notifications.toast("Default text saved");
                    instance.posting = false;
                }
            }).catch((err) => {
                if (err.response.data && err.response.data.errors) {
                    instance.validationErrors = err.response.data.errors;
                } else {
                    instance.validationErrors.push({
                        message: "An error occurred",
                    });
                }
                    
                instance.posting = false;
            });
    };
    
    mounted(): void {
        var instance = this;
        
        instance.id = Number(instance.$route.params.id);
        instance.tenantId = Number(instance.$route.params.tenantId);
        
        instance.fetchLanguages()
            .then((result) => {
                instance.fetch();        
            }).catch((err) => {
                
            });
    };
};
