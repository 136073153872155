export default class PagedList<T> {
    constructor(pageSize = null) {
        if (pageSize) {
            this.pageSize = pageSize;    
        }
    }
    
    public currentPage: Number = 1;
    public pageIndex: Number = 0;
    public pageSize: Number = 1;
    public totalCount: Number = 0;
    public totalPages: Number = 0;
    public previousPage: Number = 0;
    public nextPage: Number = 0;
    public items: T[] = [];
    public hasPreviousPage: Boolean = false;
    public hasNextPage: Boolean = false;
};