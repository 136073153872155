
































































import Vue from "vue";
import { Component, Watch, Prop } from "vue-property-decorator";
import axios from "axios";
import _ from "lodash";

import TemplateViewModel from "@/models/TemplateViewModel";
import TemplateCategoryViewModel from "@/models/TemplateCategoryViewModel";

@Component
export default class TemplateEdit_Categories extends Vue {
    @Prop()
    template: TemplateViewModel;
    
    @Prop()
    savedTemplate: TemplateViewModel;
    
    categories = [];
    
    showCategoriesDialog: Boolean = false;
    
    get hasPendingChanges(): Boolean {
        if (this.savedTemplate == null)
            return false;
            
        return !_.isEqual(this.savedTemplate.categories, this.template.categories);
    };
    
    onCategoryDefaultClick(category: TemplateCategoryViewModel): void {
        const instance = this;
        
        category.isDefault = true;
        
        instance.template.categories.forEach(c => {
            if (c.categoryId != category.categoryId) {
                c.isDefault = false;
            }
        });
    };
    
    onOpenCategoriesModalClick(e): void {
        const instance = this;
        
        axios.get(`/categories?tenantId=${instance.template.tenant.id}`)
            .then((result) => {
                instance.categories = result.data;
                
                // Preselect categories
                instance.preselectCategories(instance.template.categories, instance.categories);
            }).catch((err) => {
                
            });
        
        this.showCategoriesDialog = true;
    };
    
    onRemoveCategory(category): void {
        const instance = this;
        
        instance.template.categories = instance.template.categories.filter(f => f != category);
        
        instance.ensureThereIsADefaultCategory();
    };
    
    onSelectCategoriesConfirmClick(e): void {
        const instance = this;
        
        instance.categories.forEach(c => {
            instance.inspectSelectedAndUpdateTemplateCategories(c);
        });
        
        instance.ensureThereIsADefaultCategory();
        
        instance.showCategoriesDialog = false;
    };
    
    inspectSelectedAndUpdateTemplateCategories(category) {
        const instance = this;
        
        // Process current category
        if (category.selected == true) {
            if (_.find(instance.template.categories, (c) => c.categoryId == category.id) == null) {
                let tc = new TemplateCategoryViewModel();
                tc.categoryId = category.id;
                tc.categoryName = category.text;
                
                instance.template.categories.push(tc)    
            }
        } else {
            if (_.find(instance.template.categories, (c) => c.categoryId == category.id)) {
                _.remove(instance.template.categories, (c) => c.categoryId == category.id);
            }
        }
        
        // Traverse tree
        if (category.children) {
            category.children.forEach(c => {
                instance.inspectSelectedAndUpdateTemplateCategories(c);
            });
        }
    };
    
    categoryClick(node, item): void {
        const instance = this;

        if (item.selected == true) {
            // Deselect all the upper hierarchy
            instance.deselectParents(node.$parent);
            
            // Deselect all the lower hierarchy 
            // TODO: I'm not sure this has to be work like this
            node.$children.forEach(element => {
                instance.deselectChildren(element);
            });
        }
    };
    
    /**
     * Finds all the selected parents of the given node and deselect them
     */
    deselectParents(node) {
        if (node != undefined && node != null && node.$vnode.tag.indexOf("VJstree") == -1) {
            node.data.selected = false;
            
            this.deselectParents(node.$parent);
        }
    }
    
    /**
     * Finds all the children of the given node and deselect them
     */
    deselectChildren(node) {
        const instance = this;
        
        node.data.selected = false;
        
        if (node.$children.length > 0) {
            node.$children.forEach(element => {
                instance.deselectChildren(element);
            });
        }
    }
    
    ensureThereIsADefaultCategory(): void {
        const instance = this;
        
        if (instance.template.categories.length > 0 && 
            _.findIndex(instance.template.categories, (i) => i.isDefault == true) == -1) {
            instance.template.categories[0].isDefault = true;
        }
    };
    
    /**
     * Preselect all the nodes that have a reference in the templateCategories list
     */
    preselectCategories(templateCategories: TemplateCategoryViewModel[], categories): void {
        const instance = this;
        
        categories.forEach(c => {
            instance.traverseAndSelect(templateCategories, c);
        });
    };
    
    /**
     * Traverse recursively the tree and select all the nodes that have a reference in the templateCategories list
     */
    traverseAndSelect(templateCategories: TemplateCategoryViewModel[], category): void {
        const instance = this;
        if (_.find(templateCategories, (c) => c.categoryId == category.id)) {
            category.selected = true;
        }
        
        if (category.children) {
            category.children.forEach(c => {
                instance.traverseAndSelect(templateCategories, c);
            });
        }
    };
    
    onRevertCategoriesChangesClick(e): void {
        e.preventDefault();
        
        this.template.categories = _.cloneDeep(this.savedTemplate.categories);
    };
};
