import Vue from "vue";

class Notifications {
    confirm(title: string, message: string, confirmCallback: Function, cancelCallback: Function) {
        Vue.swal.fire({
            title: title,
            text: message,
            showDenyButton: true,
            showCancelButton: false,
            confirmButtonText: "Yes",
            denyButtonText: "No"
        }).then((result) => {
            if (result.isConfirmed) {
              confirmCallback();
            } else if (result.isDenied) {
              cancelCallback();
            }
          });
    };
    
    alert(title: string, message: string, customClass: any = {}, width: string = null) {
        console.log(customClass);
        
        Vue.swal.fire({
            title: title,
            html: message,
            customClass: customClass,
            showCancelButton: false,
            width: width
        });
    };
    
    toast(title: String = "test", _icon: any = "success") {
        Vue.swal.fire({
            toast: true,
            position: 'top-end',
            icon: _icon,
            title: title,
            showConfirmButton: false,
            timer: 1500
        })  
    };
}

export default new Notifications();