import ImportBatch from "./ImportBatch";
import LanguageDTO from "./LanguageDTO";
import PagedList from "./PagedList";

export default class EditBatchImportViewModel {
    constructor(defaultPageSize: number) {
        this.defaultPageSize = defaultPageSize;
        this.importRows = new PagedList<any>(this.defaultPageSize);
    }
    
    defaultPageSize: number = 1;
    
    public importBatch: ImportBatch = new ImportBatch();
    public importRows: PagedList<any>;
    public availableLanguages: LanguageDTO[] = [];
};