import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { mapState } from 'vuex';
import _ from "lodash";

import { EventBus } from "../utils/EventBus";
import Notifications from "../utils/Notifications";

@Component
export default class BasePage extends Vue {
    /**
     * Helpers to interact with the predefined notification system (toast, confirm, etc...)
     */
    notifications = Notifications;
    
    /**
     * When is set to true an overlay layer appears to block the UI
     */
    posting: Boolean = false;
    
    _ = _;
    
    @Watch("posting")
    onPostingChange(to, from): void {
        EventBus.$emit("page-loading", to);
    };
    
    get currentUser(): any {
        return this.$store.getters.currentUser;
    };
};