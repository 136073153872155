








































import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component
export default class Login extends Vue {
    username: String = "";

    password: String = "";

    showError: Boolean = false;
    
    error: string = "";
    
    posting: Boolean = false;

    performLogin() {
        const instance = this;

        let username = instance.username;
        let password = instance.password;

        instance.posting = true;

        instance.$store
            .dispatch("login", { username, password })
            .then(() => {
                instance.$store.dispatch("getTCMSSettings")
                    .then((result) => {
                        instance.$router.push("/");
                        instance.posting = false;        
                    }).catch((err) => {
                        
                    });
            })
            .catch((err) => {
                if (err.response && err.response.data) {
                    instance.error = err.response.data.message;
                } else {
                    instance.error = "An error occurred";
                }
                
                instance.showError = true;
                instance.posting = false;
            });
    };
    
    onKeyUp(e) {
        if (e.keyCode === 13) {
            this.performLogin();
        }
    };

    btnLoginOnClick(event: any) {
        this.performLogin();
    };
    
    onEnterPressed(e) {
        this.performLogin();
    };
}
