










































import Vue from "vue";
import { Component } from "vue-property-decorator";

import { EventBus } from "../utils/EventBus";

@Component
export default class Layout extends Vue {
    showOverlay: Boolean = false;
    
    get currentUser(): any {
        return this.$store.getters.currentUser;
    };
    
    btnLogoutOnClick(): void {
        const instance = this;

        instance.$store
            .dispatch("logout")
            .then(() => {
                instance.$router.push("/login");
            })
            .catch((err) => {
                console.log(err);
            });
    };
    
    mounted(): void {
        const instance = this;
        
        EventBus.$on("page-loading", (val) => {
            instance.showOverlay = val;
        });
    };
};
