




import Vue from "vue";
import axios from "axios";
import { Component } from "vue-property-decorator";

@Component
export default class App extends Vue {
    created() {
        const instance = this;

        axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
        axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
        
        axios.interceptors.response.use(undefined, function(err) {
            return new Promise(function(resolve, reject) {
                if (err.response.status === 401 /*&&
                    err.response.config &&
                    !err.response.config.__isRetryRequest*/
                ) {
                    instance.$store.dispatch("logout");
                    instance.$router.push("/login");
                } else if (err.response.status === 404) {
                    instance.$router.push("/notfound");
                }
                
                throw err;
            });
        });
    }
}
