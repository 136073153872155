import LanguageDTO from "./LanguageDTO";
import LocalizedTagViewModel from "./LocalizedTagViewModel";
import TemplateCategoryViewModel from "./TemplateCategoryViewModel";
import TemplateFAQViewModel from "./TemplateFAQViewModel";
import TemplateFileViewModel from "./TemplateFileViewModel";
import TemplateLiteViewModel from "./TemplateLiteViewModel";
import TemplateMediaViewModel from "./TemplateMediaViewModel";
import TemplateStatus from "./TemplateStatus";
import TenantLite from "./TenantLite";
import TodoViewModel from "./TodoViewModel";
import UserLite from "./UserLite";

export default class TemplateViewModel {
    public id: string = "";
    public code: String = "";
    public tenant: TenantLite = new TenantLite();
    public language: LanguageDTO = new LanguageDTO();
    public title: string = "";
    public description: String = "";
    public content: String = "";
    public isPremium: Boolean = false;
    public price: Number = 0;
    public metaTitle: String = "";
    public urlSlug: String = "";
    public metaDescription: String = "";
    public isDeleted: Boolean = false;
    public isPublished: Boolean = false;
    public status: TemplateStatus = TemplateStatus.New;
    public createdDate: Date = new Date();
    public createdBy: UserLite = new UserLite();
    public lastUpdatedDate: Date = new Date();
    public lastUpdatedBy: UserLite = new UserLite();
    public publishedDate: Date = new Date();
    public publishedBy: UserLite = new UserLite();
    public statusChangedDate: Date = new Date();
    public statusChangedBy: UserLite = new UserLite();
    
    public categories: TemplateCategoryViewModel[] = [];
    public tags: LocalizedTagViewModel[] = [];
    public media: TemplateMediaViewModel[] = [];
    public files: TemplateFileViewModel[] = [];
    public faQs: TemplateFAQViewModel[] = [];
    
    public relatedTemplatesByLanguage: TemplateLiteViewModel[] = [];
    public relatedTemplatesByTenant: TemplateLiteViewModel[] = [];
    
    public templatePropertyStrings: any[] = [];
    public todos: TodoViewModel[] = [];
    // TODO: Other fields
};