
























import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import axios from "axios";
import _ from "lodash";

import Constants from "@/utils/Constants";
import Notifications from "@/utils/Notifications";

import TemplateViewModel from "@/models/TemplateViewModel";

@Component
export default class TemplateEdit_CustomProperties extends Vue {
    @Prop()
    template: TemplateViewModel;
    
    @Prop()
    availableCustomPropertiesInt: any[];
    
    @Prop()
    availableCustomPropertiesString: any[];
    
    get anyCustomProperty() : Boolean {
        return this.availableCustomPropertiesInt.length > 0 || this.availableCustomPropertiesString.length > 0;
    };
    
    mounted(): void {
        
    };
}
