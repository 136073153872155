





































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import axios, { CancelTokenSource } from "axios";
import _ from "lodash";

import BasePage from "./BasePage";
import TemplateLiteViewModel from "../models/TemplateLiteViewModel";
import PagedList from "../models/PagedList";
import TemplateStatus from "@/models/TemplateStatus";

const CancelToken = axios.CancelToken;

@Component
export default class Templates extends BasePage {
    headers: any[] = [
        {
            text: "Id",
            value: "id",
            sortable: false
        },
        {
            text: "Tenant",
            value: "tenantName",
        },
        {
            text: "Code",
            value: "code",
            sortable: false
        },
        {
            text: "Title",
            value: "title",
        },
        {
            text: "Language",
            value: "languageName",
        },
        {
            text: "Status",
            value: "status"
        },
        {
            text: "Created",
            value: "createdDate"
        },
        {
            text: "Created By",
            value: "createdByUsername"
        },
        {
            text: "",
            value: "actions",
            sortable: false,
            align: "end"
        }
    ];
    
    options = {};
    
    defaultPageSize: Number = 40;
    
    footerProps = { 
        'items-per-page-options': [10, 20, 40],
        options: {
            page: 1,
            itemsPerPage: 1,
            sortBy: ['createdDate'],
            sortDesc: [true]
        }
    };
    
    templates: PagedList<TemplateLiteViewModel> = new PagedList<TemplateLiteViewModel>(this.defaultPageSize);
    
    statuses: any[] = [ 
        { value: null, label: "-" }, 
        { value: TemplateStatus.New, label: "New" }, 
        { value: TemplateStatus.Concept, label: "Concept" }, 
        { value: TemplateStatus.UnderReview, label: "UnderReview" }, 
        { value: TemplateStatus.Published, label: "Published" }, 
        { value: TemplateStatus.Unknown, label: "Unknown" } 
    ];
    
    search = {
        title: "",
        code: "",
        status: null,
        isPremium: null
    };
    
    changingSorting: Boolean = false;
    
    currentRequestToken: CancelTokenSource = null;
    
    @Watch("$route")
    onRouteChange(to, from) {
        var instance = this;

        instance.readRouteParams(to);

        this.fetchTemplates();
    };
    
    @Watch("footerProps.options.sortDesc")
    onSortDescChange(to, from): void {
        this.applyFilters();
    };
    
    @Watch("footerProps.options.sortBy")
    onSortByChange(): void {
        this.applyFilters();
    };
    
    getStatus(status: number): String {
        return TemplateStatus[status].toString();
    };
    
    onPageChange(page): void {
        let query = _.cloneDeep(this.$route.query);
        query.page = page;
        
        (this.$router as any).push({ name: "Templates", query: query});    
    };
    
    onPageSizeChange(pageSize): void {
        let query = _.cloneDeep(this.$route.query);
        query.page = String(1);
        query.pageSize = pageSize;
        
        (this.$router as any).push({ name: "Templates", query: query});
    };
    
    onFilterResetClick(e): void {
        this.search.title = "";
        this.search.code = "";
        this.search.status = null;
        this.search.isPremium = null;
    };
    
    fetchTemplates(): void {
        const instance = this;
        
        instance.posting = true;
        
        // Trying to avoid multiple (and concurrent) requests due to the way the data table options work:
        // If there's already a request token registered I cancel its execution to initiate the most recent one
        if (instance.currentRequestToken) {
            instance.currentRequestToken.cancel();
        }
        
        instance.currentRequestToken = CancelToken.source();
        
        axios.get<PagedList<TemplateLiteViewModel>>("/templates", {
            cancelToken: instance.currentRequestToken.token,
            params: {
                page: instance.templates.currentPage,
                pageSize: instance.templates.pageSize,
                title: instance.search.title,
                code: instance.search.code,
                status: instance.search.status,
                isPremium: instance.search.isPremium,
                sort: `${(instance.footerProps.options.sortDesc[0] == true ? "desc" : "asc")}(${instance.footerProps.options.sortBy[0]})`
            }
        })
            .then((result) => {
                instance.templates = result.data;
                instance.footerProps.options.page = instance.templates.currentPage as number;
                instance.footerProps.options.itemsPerPage = instance.templates.pageSize as number;
                instance.posting = false;
            }).catch((err) => {
                instance.posting = false;
            });
    };
    
    readRouteParams(route) {
        var instance = this;
        
        instance.templates.currentPage = route.query.page != null ? parseInt(route.query.page, 10) : 1;
        instance.templates.pageSize = route.query.pageSize != null ? parseInt(route.query.pageSize, 10) : instance.defaultPageSize;
        instance.search.title = route.query.title;
        instance.search.code = route.query.code;
        instance.search.status = route.query.status;
        instance.search.isPremium = route.query.isPremium;
    };

    applyFilters(): void {
        const instance = this;

        let query = {
            page: 1,
            title: instance.search.title,
            code: instance.search.code
        };

        if (instance.search.status != null) {
            query["status"] = instance.search.status;
        }

        if (instance.search.isPremium != null) {
            query["isPremium"] = instance.search.isPremium;
        }
        
        if (instance.footerProps.options.sortBy != null) {
            query["sort"] = `${(instance.footerProps.options.sortDesc[0] == true ? "desc" : "asc")}(${instance.footerProps.options.sortBy[0]})`;
        }

        (this.$router as any).push({
            name: "Templates",
            query: query
        },
            () => { },
            () => {
                // onAbort:
                // If the route is not changing because the params are the same, it'll fire this callback
                instance.fetchTemplates();
            });
    };

    onDeleteClick(item: TemplateLiteViewModel): void {
        const instance = this;
        
        instance.notifications.confirm("Confirm", 
                                       `Do you want to delete the template '${ item.title }'?`,
                                       () => {
                                            instance.posting = true;
                                           
                                            axios.delete(`/templates/${item.id}`)
                                                .then((result) => {
                                                    instance.fetchTemplates();
                                                    instance.posting = false;
                                                }).catch((err) => {
                                                    instance.posting = false;
                                                });
                                       },
                                       () => {});
    };

    onSearch(e) {
        e.preventDefault();
        
        const instance = this;
        
        instance.applyFilters();
    };
    
    mounted(): void {
        this.readRouteParams(this.$route)

        this.fetchTemplates();
    };
};

