

































import Vue from "vue";
import { Component } from "vue-property-decorator";
import axios from "axios";

@Component
export default class ChangePassword extends Vue {
    oldPassword: String = "";
    newPassword: String = "";
    confirmNewPassword: String = "";
    
    btnSaveOnClick(event: any) {
        const instance = this;
        
        axios.post<any>("/account/change-password", { 
            currentPassword: instance.oldPassword,
            newPassword: instance.newPassword,
            confirmNewPassword: instance.confirmNewPassword
        })
            .then(() => {
                instance.oldPassword = "";
                instance.newPassword = "";
                instance.confirmNewPassword = "";
                
                // TODO: notify and redirect?
            })
            .catch(() => {
                // TODO: manage errors and validation errors
            });
    };
}
