





























































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import axios, { CancelTokenSource } from "axios";
import _ from "lodash";
import { required, max } from "vee-validate/dist/rules";
import {
    extend,
    ValidationObserver,
    ValidationProvider,
    setInteractionMode,
} from "vee-validate";

import BasePage from "./BasePage";
import PagedList from "@/models/PagedList";
import TenantLite from "@/models/TenantLite";

const CancelToken = axios.CancelToken;

setInteractionMode("eager");

extend("required", {
    ...required,
    message: "{_field_} can not be empty",
});

extend("max", {
    ...max,
    message: "{_field_} must be maximum {length} characters",
});

@Component({
    components: {
        ValidationObserver,
        ValidationProvider
    },
})
export default class BatchImports extends BasePage {
    headers: any[] = [
        {
            text: "Id",
            value: "id",
            sortable: false
        },
        {
            text: "Tenant",
            value: "tenant.name",
            sortable: false
        },
        {
            text: "Name",
            value: "name",
            sortable: false
        },
        {
            text: "Description",
            value: "description",
            sortable: false
        },
        {
            text: "CreatedDate",
            value: "createdDate"
        },
        {
            text: "Status",
            value: "status"
        },
        {
            text: "",
            value: "actions",
            sortable: false,
            align: "end"
        }
    ];
    
    options = {};
    
    defaultPageSize: Number = 40;
    
    footerProps = { 
        'items-per-page-options': [10, 20, 40],
        options: {
            page: 1,
            itemsPerPage: 1,
            sortBy: ['createdDate'],
            sortDesc: [true]
        }
    };
    
    batches: PagedList<any> = new PagedList<any>(this.defaultPageSize);
    
    search = {
        text: "",
        status: null
    };
    
    statuses: any[] = [ 
        { value: null, label: "-" }, 
        { value: "Initial", label: "Initial" }, 
        { value: "Preenrollment", label: "Preenrollment" },
        { value: "Processed", label: "Processed" }
    ];
    
    changingSorting: Boolean = false;
    
    currentRequestToken: CancelTokenSource = null;
    
    showDialogNewBatch: Boolean = false;
    
    newBatch: any = {
        name: "",
        description: "",
        tenantId: null
    };
    
    availableTenants: TenantLite[] = [];
    
    validationErrors: any[] = [];

    $refs!: {
        observer: InstanceType<typeof ValidationObserver>;
    };
    
    validNewBatch: Boolean = false;
    
    @Watch("$route")
    onRouteChange(to, from) {
        var instance = this;

        instance.readRouteParams(to);

        this.fetch();
    };
    
    @Watch("footerProps.options.sortDesc")
    onSortDescChange(to, from): void {
        this.applyFilters();
    };
    
    @Watch("footerProps.options.sortBy")
    onSortByChange(): void {
        this.applyFilters();
    };
    
    onFilterResetClick(e): void {
        this.search.text = "";
        this.search.status = null;
    };
    
    onPageChange(page): void {
        let query = _.cloneDeep(this.$route.query);

        query.page = page;
        
        (this.$router as any).push({ name: "BatchImports", query: query});    
    };
    
    onPageSizeChange(pageSize): void {
        let query = _.cloneDeep(this.$route.query);
        query.page = String(1);
        query.pageSize = pageSize;
        
        (this.$router as any).push({ name: "BatchImports", query: query});
    };
    
    fetch(): void {
        const instance = this;
        
        instance.posting = true;
        
        // Trying to avoid multiple (and concurrent) requests due to the way the data table options work:
        // If there's already a request token registered I cancel its execution to initiate the most recent one
        if (instance.currentRequestToken) {
            instance.currentRequestToken.cancel();
        }
        
        instance.currentRequestToken = CancelToken.source();
        
        axios.get("/batchimports", {
            cancelToken: instance.currentRequestToken.token,
            params: {
                page: instance.batches.currentPage,
                pageSize: instance.batches.pageSize,
                text: instance.search.text,
                status: instance.search.status,
                sort: `${(instance.footerProps.options.sortDesc[0] == true ? "desc" : "asc")}(${instance.footerProps.options.sortBy[0]})`
            }
        })
            .then((result) => {
                instance.batches = result.data;
                instance.posting = false;
            }).catch((err) => {
                instance.posting = false;
            });
    };
    
    readRouteParams(route) {
        var instance = this;
        
        instance.batches.currentPage = route.query.page != null ? parseInt(route.query.page, 10) : 1;
        instance.batches.pageSize = route.query.pageSize != null ? parseInt(route.query.pageSize, 10) : instance.defaultPageSize;
        instance.search.text = route.query.text;
        instance.search.status = route.query.status;
    };
    
    applyFilters(): void {
        const instance = this;

        let query = {
            page: 1,
            text: instance.search.text
        };

        if (instance.search.status != null) {
            query["status"] = instance.search.status;
        }
        
        if (instance.footerProps.options.sortBy != null) {
            query["sort"] = `${(instance.footerProps.options.sortDesc[0] == true ? "desc" : "asc")}(${instance.footerProps.options.sortBy[0]})`;
        }

        (this.$router as any).push({
            name: "BatchImports",
            query: query
        },
            () => { },
            () => {
                // onAbort:
                // If the route is not changing because the params are the same, it'll fire this callback
                instance.fetch();
            });
    };
    
    onSearch(e) {
        e.preventDefault();
        
        const instance = this;
        
        instance.applyFilters();
    };
    
    onNewBatchClick(): void {
        this.showDialogNewBatch = true;
    };
    
    onConfirmNewBatch(): void {
        const instance = this;

        instance.validationErrors = [];
        instance.$refs.observer.validate()
            .then((result) => {
                
                instance.validNewBatch = result;
                
                if (instance.validNewBatch) {
                    instance.posting = true;
                    
                    axios
                        .post("/batchimports", instance.newBatch)
                        .then((result) => {
                            if (result.status == 201) {
                                instance.$router.push("/batchimports/" + result.data.id);
                            }
                        })
                        .catch((err) => {
                            if (err.response.data && err.response.data.errors) {
                                instance.validationErrors = err.response.data.errors;
                            } else {
                                instance.validationErrors.push({
                                    message: "An error occurred",
                                });
                            }

                            instance.posting = false;
                        });
                }
                
            }).catch((err) => {
                
            });
        
        
    };
    
    mounted(): void {
        const instance = this;
        
        instance.fetch();
        
        axios.get("tenants/available-for-user")
            .then((result) => {
                instance.availableTenants.push({ id: null, name: "-" });
                instance.availableTenants = _.concat(instance.availableTenants, result.data);
            }).catch((err) => {
                
            });
    }
}
