















































import Vue from "vue";
import { Component } from "vue-property-decorator";
import axios from "axios";
import BasePage from "./BasePage";

@Component
export default class Users extends BasePage {
    users: any[] = [];

    headers: any[] = [
        {
            text: "UserName",
            value: "userName",
        },
        {
            text: "Email",
            value: "email",
        },
        {
            text: "Roles",
            value: "roles",
            sortable: false,
            align: "end"
        },
        {
            text: "Disabled",
            value: "disabled",
            sortable: false,
            align: "end"
        },
        {
            text: "",
            value: "actions",
            sortable: false,
            align: "end"
        }
    ];

    fetch() {
        const instance = this;

        axios
            .get("/account/users")
            .then((result) => {
                instance.users = result.data;
            })
            .catch((err) => {});
    }

    mounted() {
        this.fetch();
    }
}
