import TemplateLiteViewModel from "./TemplateLiteViewModel";
import TenantLite from "./TenantLite";

export default class ReviewViewModel {
    public id: number = 0;
    public tenantId: number = 0;
    public templateId: string = "";
    public publicName: string = "";
    public rating?: number = null;
    public ip: string = "";
    public content: string = "";
    public createdDate: Date = new Date();
    public mayPublic: Boolean = false;
    public countryISO3Code: string = "";
    public email: string = "";
    public isPublished: Boolean = false;
    public isDeleted: Boolean = false;
    public isGenerated: Boolean = false;
    public source: string = "";
    public template: TemplateLiteViewModel = null;
    public tenant: TenantLite = null;
}