import UserLite from "./UserLite";

export default class TodoViewModel {
    public id: number = 0;
    public tenantId: number = 0;
    public templateId: string = "";
    public displayOrder: number = 1;
    public title: string = "";
    public description: string = "";
    public completed: Boolean = false;
    public deleted: Boolean = false;
    public createdDate: Date = new Date();
    public createdBy: UserLite = new UserLite();
    public lastUpdatedDate: Date = new Date();
    public lastUpdatedBy: UserLite = new UserLite();
    public completedDate: Date = new Date();
    public completedBy: UserLite = new UserLite();
    public tenantName: string = "";
    public templateName: string = "";
}