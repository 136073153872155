




































































































































import Vue from "vue";
import { Component, Watch, Prop } from "vue-property-decorator";
import axios from "axios";
import _ from "lodash";

import TemplateViewModel from "@/models/TemplateViewModel";
import Constants from "@/utils/Constants";
import Notifications from "@/utils/Notifications";
import TodoViewModel from "@/models/TodoViewModel";

@Component
export default class TemplateEdit_Todos extends Vue {
    @Prop()
    template: TemplateViewModel;
    
    @Prop()
    savedTemplate: TemplateViewModel;
    
    showModal: Boolean = false;
    
    newTodo: TodoViewModel = new TodoViewModel();
    
    showNewTodoRow: Boolean = false;
    
    /**
     * Is true if an AJAX operation is in progress
     */
    posting: Boolean = false;
    
    get isNew(): Boolean {
        return this.template.id == Constants.newId;
    }
    
    get orderedTodos(): TodoViewModel[] {
        return _.sortBy(this.template.todos, o => o.displayOrder);
    };
    
    onOpenTodosClick(): void {
        this.showModal = true;
    };
    
    onCompletedMark(e, item: TodoViewModel): void {
        const instance = this;
        
        instance.posting = true;
        axios.post(`/templates/${instance.template.id}/todos/${item.id}?completed=${e}`)
            .then((result) => {
                instance.template.todos = result.data;
                instance.savedTemplate.todos = result.data;
                
                instance.posting = false;
            }).catch((err) => {
                if (err.response.data) {
                    Notifications.alert("Warning", err.response.data.map(m => m.description).join("<br>"));
                }
                    
                instance.posting = false;
            });
    };
    
    onAddTodoClick(): void {
        this.newTodo.title = "";
        this.newTodo.tenantId = this.template.tenant.id;
        this.newTodo.templateId = this.template.id;
        
        if (this.template.todos.length > 0) {
            this.newTodo.displayOrder = _.max(this.template.todos.map(m => m.displayOrder)) + 1;
        } else {
            this.newTodo.displayOrder = 1;
        }
        
        this.showNewTodoRow = true;
    };
    
    onSaveTodoClick(): void {
        const instance = this;
        
        instance.posting = true;
        axios.post(`/templates/${instance.template.id}/todos`, instance.newTodo)
            .then((result) => {
                instance.template.todos = result.data;
                instance.savedTemplate.todos = result.data;
                
                instance.posting = false;
                instance.showNewTodoRow = false;
            }).catch((err) => {
                if (err.response.data) {
                    Notifications.alert("Warning", err.response.data.map(m => m.description).join("<br>"));
                }
                    
                instance.posting = false;
            });
    };
    
    onCancelSaveTodoClick(): void {
        this.showNewTodoRow = false;
    };
    
    onDeleteTodo(item: TodoViewModel): void {
        const instance = this;
        
        Notifications.confirm("Confirm", "Do you really want to delete this todo?", 
            () => {
                instance.posting = true;
                axios.delete(`/templates/${instance.template.id}/todos/${item.id}`)
                    .then((result) => {
                        instance.template.todos = result.data;
                        instance.savedTemplate.todos = result.data;
                        
                        instance.posting = false;
                    }).catch((err) => {
                        if (err.response.data) {
                            Notifications.alert("Warning", err.response.data.map(m => m.description).join("<br>"));
                        }
                            
                        instance.posting = false;
                    });
            },
            () => {
                
            });
    };
    
    onChangeOrderClick(item: TodoViewModel, i: number): void {
        const instance = this;
        
        instance.posting = true;
        axios.post(`/templates/${instance.template.id}/todos/${item.id}/displayOrder?i=${i}`)
            .then((result) => {
                instance.template.todos = result.data;
                instance.savedTemplate.todos = result.data;
                
                instance.posting = false;
            }).catch((err) => {
                if (err.response.data) {
                    Notifications.alert("Warning", err.response.data.map(m => m.description).join("<br>"));
                }
                    
                instance.posting = false;
            });
    };
    
    hideModal(): void {
        this.showModal = false;
        this.showNewTodoRow = false;
    };
};
