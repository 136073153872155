










import Vue from "vue";
import { Component } from "vue-property-decorator";
import axios from "axios";

import BasePage from "./BasePage";

@Component
export default class Dashboard extends BasePage {
    mounted(): void {
        axios.get("/dashboard")
            .then((result) => {
                
            }).catch((err) => {
                
            });
    }
};
