

































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import axios from "axios";
import _ from "lodash";

import BasePage from "./BasePage";
import PagedList from "@/models/PagedList";
import DefaultTextViewModel from "@/models/DefaultTextViewModel";

@Component
export default class DefaultTexts extends BasePage {
    tenantId: number = 0;
    
    defaultTexts: PagedList<DefaultTextViewModel> = new PagedList<DefaultTextViewModel>();
    
    headers: any[] = [
        {
            text: "Code",
            value: "code",
            sortable: false
        },
        {
            text: "Languages",
            value: "languages",
        },
        {
            text: "Created",
            value: "createdDate"
        },
        {
            text: "Created By",
            value: "createdByUsername"
        },
        {
            text: "Last Updated",
            value: "lastUpdatedDate"
        },
        {
            text: "Last Updated By",
            value: "lastUpdatedByUsername"
        },
        {
            text: "",
            value: "actions",
            sortable: false,
            align: "end"
        }
    ];
    
    search = {
        tenantId: 0,
        text: "",
        code: ""
    };
    
    defaultPageSize: number = 20;
    
    @Watch("$route")
    onRouteChange(to, from) {
        var instance = this;

        instance.readRouteParams(to);

        this.fetch();
    };
    
    readRouteParams(route) {
        var instance = this;
        
        instance.defaultTexts.currentPage = route.query.page != null ? parseInt(route.query.page, 10) : 1;
        instance.defaultTexts.pageSize = route.query.pageSize != null ? parseInt(route.query.pageSize, 10) : instance.defaultPageSize;
        instance.search.tenantId = Number(route.params.tenantId);
        instance.search.text = route.query.text;
        instance.search.code = route.query.code;
    };
    
    applyFilters(): void {
        const instance = this;

        let query = {
            page: 1,
            text: instance.search.text,
            code: instance.search.code
        };

        (this.$router as any).push({
            name: "DefaultTexts",
            query: query
        },
            () => { },
            () => {
                // onAbort:
                // If the route is not changing because the params are the same, it'll fire this callback
                instance.fetch();
            });
    };
    
    onSearch(e): void {
        e.preventDefault();
        
        const instance = this;
        
        instance.applyFilters();
    };
    
    onPageChange(page): void {
        let query = _.cloneDeep(this.$route.query);
        query.page = page;
        
        (this.$router as any).push({ name: "DefaultTexts", query: query});    
    };
    
    onPageSizeChange(pageSize): void {
        let query = _.cloneDeep(this.$route.query);
        query.page = String(1);
        query.pageSize = pageSize;
        
        (this.$router as any).push({ name: "DefaultTexts", query: query});
    };
    
    onFilterResetClick(e): void {
        this.search.text = "";
        this.search.code = "";
    };
    
    onDeleteClick(item: DefaultTextViewModel): void {
        const instance = this;
        
        instance.notifications.confirm("Confirm", 
                                       `Do you want to delete the default text '${ item.code }'?`,
                                       () => {
                                            instance.posting = true;
                                           
                                            axios.delete(`/default-texts/${item.id}`)
                                                .then((result) => {
                                                    instance.fetch();
                                                    instance.posting = false;
                                                }).catch((err) => {
                                                    instance.posting = false;
                                                });
                                       },
                                       () => {});
    };
    
    fetch(): void {
        var instance = this;
        
        instance.posting = true;
        
        axios.get(`/default-texts/search`, {
            params: {
                page: instance.defaultTexts.currentPage,
                pageSize: instance.defaultTexts.pageSize,
                tenantId: instance.search.tenantId,
                text: instance.search.text,
                code: instance.search.code
            }
        })
            .then((result) => {
                instance.defaultTexts = result.data;
                instance.posting = false;
            }).catch((err) => {
                instance.posting = false;
            });
    };
    
    mounted(): void {
        var instance = this;
        
        instance.tenantId = Number(instance.$route.params.tenantId);
        
        instance.readRouteParams(instance.$route);
        instance.fetch();
    };
};
