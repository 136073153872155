
























































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import axios, { CancelTokenSource } from "axios";
import _ from "lodash";

import BasePage from "./BasePage";
import PagedList from "../models/PagedList";
import TodoViewModel from "@/models/TodoViewModel";

const CancelToken = axios.CancelToken;

@Component
export default class Todos extends BasePage {
    headers: any[] = [
        {
            text: "Id",
            value: "id",
            sortable: false
        },
        {
            text: "Tenant",
            value: "tenantName",
            sortable: false
        },
        {
            text: "Title",
            value: "title",
            sortable: false
        },
        {
            text: "Completed",
            value: "completed"
        },
        {
            text: "Created",
            value: "createdDate"
        },
        {
            text: "Created By",
            value: "createdBy.userName"
        },
        {
            text: "Completed date",
            value: "completedDate"
        },
        {
            text: "Completed By",
            value: "completedBy.userName"
        },
        {
            text: "",
            value: "actions",
            sortable: false,
            align: "end"
        }
    ];
    
    statuses: any[] = [ 
        { value: null, label: "-" }, 
        { value: false, label: "Open" }, 
        { value: true, label: "Completed" }
    ];
    
    options = {};
    
    defaultPageSize: Number = 40;
    
    footerProps = { 
        'items-per-page-options': [10, 20, 40],
        options: {
            page: 1,
            itemsPerPage: 1,
            sortBy: ['createdDate'],
            sortDesc: [true]
        }
    };
    
    todos: PagedList<TodoViewModel> = new PagedList<TodoViewModel>(this.defaultPageSize);
    
    search = {
        title: "",
        status: null
    };
    
    changingSorting: Boolean = false;
    
    currentRequestToken: CancelTokenSource = null;
    
    @Watch("$route")
    onRouteChange(to, from) {
        var instance = this;

        instance.readRouteParams(to);

        this.fetchTodos();
    };
    
    @Watch("footerProps.options.sortDesc")
    onSortDescChange(to, from): void {
        this.applyFilters();
    };
    
    @Watch("footerProps.options.sortBy")
    onSortByChange(): void {
        this.applyFilters();
    };
    
    onFilterResetClick(e): void {
        this.search.title = "";
        this.search.status = null;
    };
    
    onPageChange(page): void {
        let query = _.cloneDeep(this.$route.query);

        query.page = page;
        
        (this.$router as any).push({ name: "Todos", query: query});    
    };
    
    onPageSizeChange(pageSize): void {
        let query = _.cloneDeep(this.$route.query);
        query.page = String(1);
        query.pageSize = pageSize;
        
        (this.$router as any).push({ name: "Todos", query: query});
    };
    
    fetchTodos(): void {
        const instance = this;
        
        instance.posting = true;
        
        // Trying to avoid multiple (and concurrent) requests due to the way the data table options work:
        // If there's already a request token registered I cancel its execution to initiate the most recent one
        if (instance.currentRequestToken) {
            instance.currentRequestToken.cancel();
        }
        
        instance.currentRequestToken = CancelToken.source();
        
        axios.get<PagedList<TodoViewModel>>("/todos", {
            cancelToken: instance.currentRequestToken.token,
            params: {
                page: instance.todos.currentPage,
                pageSize: instance.todos.pageSize,
                title: instance.search.title,
                status: instance.search.status,
                sort: `${(instance.footerProps.options.sortDesc[0] == true ? "desc" : "asc")}(${instance.footerProps.options.sortBy[0]})`
            }
        })
            .then((result) => {
                instance.todos = result.data;
                instance.footerProps.options.page = instance.todos.currentPage as number;
                instance.footerProps.options.itemsPerPage = instance.todos.pageSize as number;
                instance.posting = false;
            }).catch((err) => {
                instance.posting = false;
            });
    };
    
    readRouteParams(route) {
        var instance = this;
        
        instance.todos.currentPage = route.query.page != null ? parseInt(route.query.page, 10) : 1;
        instance.todos.pageSize = route.query.pageSize != null ? parseInt(route.query.pageSize, 10) : instance.defaultPageSize;
        instance.search.title = route.query.title;
        instance.search.status = route.query.status;
    };
    
    applyFilters(): void {
        const instance = this;

        let query = {
            page: 1,
            title: instance.search.title
        };

        if (instance.search.status != null) {
            query["status"] = instance.search.status;
        }
        
        if (instance.footerProps.options.sortBy != null) {
            query["sort"] = `${(instance.footerProps.options.sortDesc[0] == true ? "desc" : "asc")}(${instance.footerProps.options.sortBy[0]})`;
        }

        (this.$router as any).push({
            name: "Todos",
            query: query
        },
            () => { },
            () => {
                // onAbort:
                // If the route is not changing because the params are the same, it'll fire this callback
                instance.fetchTodos();
            });
    };
    
    onSearch(e) {
        e.preventDefault();
        
        const instance = this;
        
        instance.applyFilters();
    };
    
    mounted(): void {
        this.readRouteParams(this.$route)

        this.fetchTodos();
    };
}
