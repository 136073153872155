export default class defaultTexts {
    constructor(quill, options = {}) {
        this.quill = quill;
        this.options = options;
    }
    
    quill;
    
    handle() {
        console.log(this.quill);
    }
}
