





























































































import Vue from "vue";
import { Component, Watch, Prop } from "vue-property-decorator";
import axios from "axios";
import _ from "lodash";
import qs from "qs";

import TemplateViewModel from "@/models/TemplateViewModel";
import LocalizedTagViewModel from "@/models/LocalizedTagViewModel";

@Component
export default class TemplateEdit_Tags extends Vue {
    @Prop()
    template: TemplateViewModel;
    
    /**
     * Tags matching the search term
     */
    availableTags: LocalizedTagViewModel[] = [];
    
    /**
     * True while the AJAX call for the tags is on
     */
    isFetchingTags: Boolean = false;
    
    /**
     * Search term for tags
     */
    searchTag: String = "";
    
    /**
     * Usefull to cleanup after tag selection (trick to bypass an issue of vuetify autocomplete)
     */
    searchedTag: String = "";
    
    /**
     * When true shows the dialog to localize the selected tag
     */
    showLocalizeDialog: Boolean = false;
    
    /**
     * Current tag in edit
     */
    tagInEdit: LocalizedTagViewModel = null;
    
    /**
     * Original tag I want to edit (needed for placeholder functionality)
     */
    originalTagToEdit: LocalizedTagViewModel = null;
    
    /**
     * Is true if an AJAX operation is in progress
     */
    posting: Boolean = false;
    
    dialogValidationErrors: any[] = [];
    
    get sortedTemplateTags(): LocalizedTagViewModel[] {
        return _.orderBy(this.template.tags, "value");
    };
    
    @Watch("searchTag")
    onSearchTagChange(to, from): void {
        var instance = this;
		
		if (to && to !== from) {
			if (!instance.isFetchingTags) {
				instance.isFetchingTags = true;
			}
            
			this.debouncedTagsSearch(to);
		} else if (!to) {
			instance.availableTags = [];
		}
    };
    
    @Watch("posting")
    onPostingChange(to) {
        this.$emit("postingChange", to);
    };

    debouncedTagsSearch = _.debounce((to) => {
        this.searchTags(to);
    } , 450);
    
    /**
     * Search tags for the current tenant/language, returns tags not already assigned to the tempalte
     * @param text Text to be searched (fulltext-style)
     */
    searchTags(text): void {
        const instance = this;
        
        let idsToExclude = instance.template.tags.map(m => m.id);
        
        axios.get("/tags/search", {
            params: {
                tenantId: instance.template.tenant.id,
                languageId: instance.template.language.id,
                text: text,
                idsToExclude: idsToExclude
            },
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: "repeat" })
            }
        })
            .then((result) => {
                instance.availableTags = result.data;
                instance.isFetchingTags = false;
            }).catch((err) => {
                instance.isFetchingTags = false;
            });
    };
    
    onAddTagChange(selectedTag): void {
        const instance = this;
        
        if (selectedTag != null) {
            if (!instance.template.tags) {
                instance.template.tags = [];
            }
            
			if (instance.template.tags.find(f => f.id == selectedTag.id) == null) {
				instance.template.tags.push(selectedTag);
			}
		}
        
        instance.$nextTick(() => {
			instance.searchedTag = "";
			instance.availableTags = [];
		})
    };
    
    onAddTagEnter(e): void {
        const instance = this;
		
		if (instance.searchTag && !instance.isFetchingTags && 
			instance.template.tags.find(f => f.value == instance.searchTag) == null) {
			let newTag = new LocalizedTagViewModel();
            newTag.id = 0;
            newTag.value = instance.searchTag;
            newTag.languageId = instance.template.language.id;
            newTag.tenantId = instance.template.tenant.id;
			
			instance.template.tags.push(newTag);
			
			instance.$nextTick(() => {
				instance.searchedTag = "";
				instance.searchTag = "";
				instance.availableTags = [];
			})
		}
    };
    
    onChipClick(tag: LocalizedTagViewModel): void {
        const instance = this;
        
        if (tag.value && tag.value.startsWith("[")) {
            instance.tagInEdit = _.clone(tag);
            instance.tagInEdit.value = "";
            instance.originalTagToEdit = _.clone(tag);
            instance.showLocalizeDialog = true;
        }
    };
    
    onSaveLocalizationClick(): void {
        const instance = this;
        
        instance.dialogValidationErrors = [];
        
        instance.posting = true;
        axios.post(`/templates/${instance.template.id}/tags/${instance.tagInEdit.id}`, instance.tagInEdit)
            .then((result) => {
                instance.template.tags = result.data;
                instance.$emit("tagsChanged");
                
                instance.posting = false;
                instance.showLocalizeDialog = false;
            }).catch((err) => {
                if (err.response.data) {
                    instance.dialogValidationErrors = err.response.data;
                }
                    
                instance.posting = false;
            });
    };
    
    removeTag(tag: LocalizedTagViewModel): void {
        this.template.tags = this.template.tags.filter(f => f != tag);
    };
};
