export default class ELMAH_Error {
    public errorId: string = "";
    public application: string = "";
    public host: string = "";
    public type: string = "";
    public source: string = "";
    public message: string = "";
    public user: string = "";
    public statusCode: number = 0;
    public timeUtc: Date = new Date();
    public sequence: number = 0;
    public allXml: string = "";
}