import DefaultTextLocaleViewModel from "./DefaultTextLocaleViewModel";
import UserLite from "./UserLite";

export default class DefaultTextViewModel {
    public id: number = 0;
    public code: string = "";
    public tenantId: number = 0;
    public createdDate: Date = new Date();
    public createdBy: UserLite = new UserLite();
    public lastUpdatedDate: Date = new Date();
    public lastUpdatedBy: UserLite = new UserLite();
    public locales: DefaultTextLocaleViewModel[] = [];
}