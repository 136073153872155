import ImportBatchStatus from "./ImportBatchStatus";
import TenantLite from "./TenantLite";
import UserLite from "./UserLite";

export default class ImportBatch {
    public id: number = 0;
    public tenantId: number = 0;
    public name: string = "";
    public description: string = "";
    public createdDate: Date = new Date();
    public createdBy: UserLite = new UserLite();
    public createdById: string = "";
    public status: ImportBatchStatus = ImportBatchStatus.Initial;
    public zipFileFolder: string = "";
    public tenant: TenantLite = new TenantLite();
}