




















































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import axios from "axios";
import _ from "lodash";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import qs from "qs";

import BasePage from "./BasePage";
import TagViewModel from "@/models/TagViewModel";
import PagedList from "@/models/PagedList";
import LanguageDTO from "@/models/LanguageDTO";
import EditTagViewModel from "@/models/EditTagViewModel";
import TagLocaleViewModel from "@/models/TagLocaleViewModel";

@Component({
    components: { ValidationObserver, ValidationProvider }
})
export default class Tags extends BasePage {
    headers = [
        {
            text: "Id",
            value: "id",
            sortable: false
        }
    ];
    
    pageTitle: String = "";
    
    footerProps = { 
        'items-per-page-options': [10, 20, 40],
        options: {
            page: 1,
            itemsPerPage: 20
        }
    };
    
    search = {
        text: "",
        missingTranslations: []
    };
    
    availableLanguages: LanguageDTO[] = [];
    
    tenantId: number = 0;
    
    tags: PagedList<EditTagViewModel> = new PagedList<EditTagViewModel>(20);
    
    showNewTagDialog: Boolean = false;
    
    newTag: TagViewModel = new TagViewModel();
    
    newTagInvalid: Boolean = false;
    
    @Watch("$route")
    onRouteChange(to, from) {
        var instance = this;

        instance.readRouteParams(to);

        this.fetchTags();
    };
    
    getRowClass(item: EditTagViewModel) {
        if (item.error)
            return "in-error";
            
        if (item.saved)
            return "saved";
    };
    
    onPageChange(page): void {
        let query = _.cloneDeep(this.$route.query);
        query.page = page;
        
        (this.$router as any).push({ name: "Tags", query: query});    
    };
    
    onPageSizeChange(pageSize): void {
        let query = _.cloneDeep(this.$route.query);
        query.page = String(1);
        query.pageSize = pageSize;
        
        (this.$router as any).push({ name: "Tags", query: query});
    };
    
    fetchLanguages(): Promise<any> {
        const instance = this;
        
        return new Promise((resolve, reject) => {
            axios.get("/languages")
                .then((result) => {
                    instance.availableLanguages = result.data;
                    resolve(null);
                }).catch((err) => {
                    reject(null);
                });    
        });
    };
    
    fetchTags(): void {
        const instance = this;
        
        instance.posting = true;
        axios.get("/tags/to-edit", {
            params: {
                tenantId: instance.tenantId,
                text: instance.search.text,
                missingTranslations: instance.search.missingTranslations.map(m => m.id),
                page: instance.tags.currentPage,
                pageSize: instance.tags.pageSize,
            },
            paramsSerializer: params => {
                return qs.stringify(params, { arrayFormat: "repeat" })
            }
        })
            .then((result) => {
                instance.tags = result.data;
                instance.posting = false;
            }).catch((err) => {
                instance.posting = false;
            });
    };
    
    readRouteParams(route) {
        var instance = this;
        
        instance.tags.currentPage = route.query.page != null ? parseInt(route.query.page, 10) : 1;
        instance.tags.pageSize = route.query.pageSize != null ? parseInt(route.query.pageSize, 10) : 20;
        instance.search.text = route.query.text;
        
        if (route.query.missingTranslations) {
            console.log(route.query.missingTranslations);
            
            if ((typeof route.query.missingTranslations) == 'string') {
                if (instance.search.missingTranslations.findIndex(f => f.id == route.query.missingTranslations) == -1)
                    instance.search.missingTranslations.push(instance.availableLanguages.find(f => f.id == route.query.missingTranslations));
            } else {
                route.query.missingTranslations.forEach(element => {
                    if (instance.search.missingTranslations.findIndex(f => f.id == element) == -1)
                        instance.search.missingTranslations.push(instance.availableLanguages.find(f => f.id == element));
                });
            }
        }
    };
    
    applyFilters(): void {
        const instance = this;

        let query = {
            page: 1,
            text: instance.search.text
        };
        
        if (instance.search.missingTranslations.length > 0) {
            query["missingTranslations"] = _.map(instance.search.missingTranslations, (i) => i.id);
        }

        (this.$router as any).push({
            name: "Tags",
            query: query
        },
            () => { },
            () => {
                // onAbort:
                // If the route is not changing because the params are the same, it'll fire this callback
                instance.fetchTags();
            });
    };
    
    onSearch(e) {
        e.preventDefault();
        
        const instance = this;
        
        instance.applyFilters();
    };
    
    onFilterResetClick(e): void {
        this.search.text = "";
        this.search.missingTranslations = [];
    };
    
    onSaveClick(item: EditTagViewModel): void {
        const instance = this;
        
        item.error = null;
        item.saving = true;
        item.saved = false;
        
        instance.posting = true;
        axios.post("/tags/to-edit", item)
            .then((result) => {
                instance.posting = false;
                item.saving = false;
                item.saved = true;
                
                setTimeout(() => {
                    item.saved = false;
                }, 2000);
            }).catch((err) => {
                console.log(err.response.data);
                if (err.response && 
                    err.response.data &&
                    err.response.data.length > 0) {
                    item.error = err.response.data[0].description;
                }
                
                item.saving = false;
                instance.posting = false;
            });
    };
    
    onAddNewTagClick(): void {
        const instance = this;
        
        instance.newTag = new TagViewModel();
        instance.newTag.tenantId = instance.tenantId;
        
        instance.availableLanguages.forEach((language) => {
            let tl = new TagLocaleViewModel();
            tl.languageId = language.id;
            
            instance.newTag.locales.push(tl);
        });
        
        
        instance.showNewTagDialog = true;
    };
    
    onSaveNewTagClick(): void {
        const instance = this;
        
        instance.posting = true;
        
        axios.post(`/tags/add`, instance.newTag)
            .then((result) => {
                instance.showNewTagDialog = false;
                instance.posting = false;
                instance.fetchTags();
            }).catch((err) => {
                instance.posting = false;
            });
    };
    
    /**
     * Construct the remaining table columns based on the current list of languages
     */
    buildTableHeader(): void {
        const instance = this;
        
        instance.availableLanguages.forEach((l) => {
            instance.headers.push({
                text: l.id.toString(),
                value: l.id.toString(),
                sortable: false
            });
        });
        
        instance.headers.push({
            text: "",
            value: "actions",
            sortable: false
        });
    };
    
    mounted(): void {
        const instance = this;
        
        instance.tenantId = Number(instance.$route.params.tenantId);

        axios.get(`/tenants/${instance.tenantId}`)
            .then((result) => {
                instance.pageTitle = `Tags of '${result.data.tenant.name}'`;
            });

        instance.fetchLanguages()
            .then((result) => {
                instance.buildTableHeader();
                
                instance.readRouteParams(instance.$route)
                instance.fetchTags();        
            }).catch((err) => {
                
            });
    };
};

